function ProductHerofeatCards() {
  return (
    <div className="grid grid-cols-3 grid-rows-2 gap-4 xxl:gap-8 p-1 xxl:p-2 mb-8 xxl:mb-16 max-lg:hidden min-w-full">
      <span className="bg-[#F2F4F7] flex flex-col justify-center items-center text-center rounded-xl lg:p-4 lg:px-8 max-lg:p-2 max-lgpx-3 xxl:p-8 border border-solid border-[#D0D5DD]">
        <span className="w-[3rem] h-[3rem] xxl:w-[4rem] xxl:h-[4rem] flex justify-center items-center bg-100 bg-product-hero-mobile-pattern bg-no-repeat"></span>
        <span className="text-[1rem] xxl:text-[1.275rem] text-[#667085] font-semibold">
          No screen time
        </span>
      </span>
      <span className="bg-[#F2F4F7] flex flex-col justify-center items-center text-center rounded-xl lg:p-4 lg:px-8 max-lg:p-2 max-lgpx-3 xxl:p-8 border border-solid border-[#D0D5DD]">
        <span className="w-[3rem] h-[3rem] xxl:w-[4rem] xxl:h-[4rem] flex justify-center items-center bg-100 bg-product-hero-music-pattern bg-no-repeat"></span>
        <span className="text-[1rem] xxl:text-[1.275rem] text-[#667085] font-semibold">
          Plays music
        </span>
      </span>
      <span className="bg-[#F2F4F7] flex flex-col justify-center items-center text-center rounded-xl lg:p-4 lg:px-8 max-lg:p-2 max-lgpx-3 xxl:p-8 border border-solid border-[#D0D5DD]">
        <span className="w-[3rem] h-[3rem] xxl:w-[4rem] xxl:h-[4rem] flex justify-center items-center bg-100 bg-product-hero-talk-pattern bg-no-repeat"></span>
        <span className="text-[1rem] xxl:text-[1.275rem] text-[#667085] font-semibold">
        Natural convos
        </span>
      </span>
      <span className="bg-[#F2F4F7] flex flex-col justify-center items-center text-center rounded-xl lg:p-4 lg:px-8 max-lg:p-2 max-lgpx-3 xxl:p-8 border border-solid border-[#D0D5DD]">
        <span className="w-[3rem] h-[3rem] xxl:w-[4rem] xxl:h-[4rem] flex justify-center items-center bg-100 bg-product-hero-sparkle-pattern bg-no-repeat"></span>
        <span className="text-[1rem] xxl:text-[1.275rem] text-[#667085] font-semibold">
          Powered by AI
        </span>
      </span>
      <span className="bg-[#F2F4F7] flex flex-col justify-center items-center text-center rounded-xl lg:p-4 lg:px-8 max-lg:p-2 max-lgpx-3 xxl:p-8 border border-solid border-[#D0D5DD]">
        <span className="w-[3rem] h-[3rem] xxl:w-[4rem] xxl:h-[4rem] flex justify-center items-center bg-100 bg-product-hero-shield-pattern bg-no-repeat"></span>
        <span className="text-[1rem] xxl:text-[1.275rem] text-[#667085] font-semibold">
          Age: 5 Years+
        </span>
      </span>
      <span className="bg-[#F2F4F7] flex flex-col justify-center items-center text-center rounded-xl lg:p-4 lg:px-8 max-lg:p-2 max-lgpx-3 xxl:p-8 border border-solid border-[#D0D5DD]">
        <span className="w-[3rem] h-[3rem] xxl:w-[4rem] xxl:h-[4rem] flex justify-center items-center bg-100 bg-product-hero-education-pattern bg-no-repeat"></span>
        <span className="text-[1rem] xxl:text-[1.275rem] text-[#667085] font-semibold">
        Playful Education
        </span>
      </span>
    </div>
  );
}

export default ProductHerofeatCards;
