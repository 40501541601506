import { motion } from 'framer-motion';
import ProductSubsHeading from '../components/productSubsHeading';
import { useInView } from 'react-intersection-observer';
import React, { useState } from 'react';

function Productsubs() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.7,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.7,
  });
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };
  return (
    <div className="flex flex-col justify-start items-center py-40 max-md:pb-8 max-md:py-16 mt-28 bg-[#F4F4F4] bg-product-subs-bg-pattern bg-no-repeat h-[100%] w-[100%] bg-subs">
      <ProductSubsHeading />
      <div
        ref={ref}
        className=" w-[90%] flex flex-row max-md:flex-col-reverse justify-between max-md:justify-center items-center my-12 max-md:my-16 max-md:space-y-0 max-md:mt-4 max-md:mb-8"
      >
        {inView && (
          <motion.div
            initial={{ x: '-100vw' }}
            animate={{ x: 0 }}
            transition={{ type: 'slide', stiffness: 120, delay: 0.5 }}
            className="w-[50%] ml-[-30px] max-md:ml-0 h-[500px] xxl:h-[640px] max-md:w-[80%] max-md:h-[500px] max-sm:w-[100%] max-sm:h-[450px] max-md:my-[-140px] max-md:mt-[-130px] flex flex-row justify-center items-center "
          >
            <img
              src={
                'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723733099/pixa-pie/product-subscription/subs_1.webp'
              }
              alt="feature-1"
              className="object-contain object-center w-[80%] max-md:w-[100%]"
            ></img>
          </motion.div>
        )}
        {inView && (
          <motion.div
            initial={{ x: '100vw' }}
            animate={{ x: 0 }}
            transition={{ type: 'slide', stiffness: 120, delay: 0.5 }}
            className="w-[50%] max-md:w-[90%] flex flex-col space-y-4 max-md:justify-center max-md:items-center max-md:text-center"
          >
            <span className="text-[#424242] leading-[3.5rem] xxl:text-[3.5rem] xxl:leading-[4rem] max-md:text-[1.625rem] max-md:leading-[2.5rem] font-bold text-[3rem]">
              Tired of having dozen subscriptions?
            </span>
            <span className="font-[400] text-[#717171] text-[1.125rem] xxl:text-[1.5rem] max-md:text-[0.875rem] pr-10 max-md:pr-0 max-md:hidden">
              Its criminal to have to buy multiple subscriptions for your kids’
              learning & manage them, all the while shelling $100s of dollars
              every month.
            </span>
          </motion.div>
        )}
      </div>
      <div
        ref={ref2}
        className="w-[90%] flex flex-row-reverse max-md:flex-col-reverse justify-between max-md:justify-center items-center my-12 max-md:my-16 max-md:mt-0 max-md:space-y-8"
      >
        {inView2 && (
          <motion.div
            initial={{ x: '100vw' }}
            animate={{ x: 0 }}
            transition={{ type: 'slide', stiffness: 120, delay: 0.5 }}
            className="overflow-hidden h-[550px] xxl:h-[750px] max-md:h-[550px] mr-[150px] xxl:mr-[200px] max-lg:mr-[60px] max-md:mr-0 max-sm:mr-0 max-xs:mr-0 flex justify-center items-center rounded-3xl max-md:mt-6"
          >
            <video
              className="w-full h-full object-contain object-center rounded-3xl block"
              style={{
                backgroundImage: isVideoLoaded
                  ? ''
                  : 'url(https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723733265/pixa-pie/product-subscription/subs_2_thumbnail.webp)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
              src={
                'https://res.cloudinary.com/dx6dieevo/video/upload/f_auto,q_auto:good,w_auto/v1723733343/pixa-pie/product-subscription/subs_2.mp4'
              }
              autoPlay
              loop
              muted
              playsInline
              onLoadedData={handleVideoLoaded}
            ></video>
          </motion.div>
        )}
        {inView2 && (
          <motion.div
            initial={{ x: '-100vw' }}
            animate={{ x: 0 }}
            transition={{ type: 'slide', stiffness: 120, delay: 0.5 }}
            className="max-w-[50%] max-md:max-w-none max-md:w-[90%] flex flex-col space-y-4 max-md:justify-center max-md:items-center max-md:text-center"
          >
            <span className="text-[#424242] leading-[3.5rem] xxl:text-[3.5rem] xxl:leading-[4rem] max-md:text-[1.625rem] max-md:leading-[2.5rem] font-bold text-[3rem]">
              One Subscription is all you need
            </span>
            <motion.span
              initial={{ x: '-100vw' }}
              animate={{ x: 0 }}
              transition={{ type: 'slide', stiffness: 120, delay: 0.5 }}
              className="font-[400] text-[#717171] text-[1.125rem] xxl:text-[1.5rem] max-md:text-[0.875rem] pr-10 max-md:pr-0 max-md:hidden"
            >
              We've made it easy for you as a parent to create educational
              experiences for your kids. Simply tell our AI what you want them
              to learn, set up a few controls, and Shazam - our system will
              devote the best AI suited to teach your child around that subject.
            </motion.span>
          </motion.div>
        )}
      </div>
      <div
        ref={ref3}
        className="w-[90%] flex flex-row max-md:flex-col-reverse justify-between max-md:justify-center items-center my-12 max-md:my-20 max-md:mb-8 max-md:mt-0 max-md:space-y-8"
      >
        {inView3 && (
          <motion.img
            initial={{ x: '-100vw' }}
            animate={{ x: 0 }}
            transition={{ type: 'slide', stiffness: 120, delay: 0.5 }}
            src={
              'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723733095/pixa-pie/product-subscription/subs_3.webp'
            }
            alt="feature-3"
            className="rounded-[100%] xl:w-[40%] xl:h-[500px] xxl:h-[740px] xxl:w-[45%] max-xl:w-[35%] max-xl:h-[450px] max-md:h-[400px] max-md:w-[60%] max-sm:w-[70%] max-sm:h-[250px] object-contain object-center max-md:mt-6"
          ></motion.img>
        )}
        {inView3 && (
          <motion.div
            initial={{ x: '100vw' }}
            animate={{ x: 0 }}
            transition={{ type: 'slide', stiffness: 120, delay: 0.5 }}
            className="w-[50%] max-md:w-[90%] flex flex-col space-y-4 max-md:justify-center max-md:items-center max-md:text-center"
          >
            <span className="text-[#424242] leading-[3.5rem] xxl:text-[3.5rem] xxl:leading-[4rem] max-md:text-[1.625rem] max-md:leading-[2.5rem] font-bold text-[3rem]">
              Stop wasting time on a dozen apps, use Pixa
            </span>
            <span className="font-[400] text-[#717171] text-[1.125rem] xxl:text-[1.5rem] max-md:text-[0.875rem] pr-10 max-md:pr-0 max-md:hidden">
              Give your kid screen free playtime with their best friend & allow
              them to learn while they play
            </span>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default Productsubs;
