import React from 'react';
import ReactDOM from 'react-dom/client';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './AllRoutes';
import { HelmetProvider } from 'react-helmet-async';
import './fontLoader';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Analytics />
    <SpeedInsights />
    <HelmetProvider>
      <BrowserRouter>
        <AllRoutes />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
