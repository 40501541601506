import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
type CustomSkeletonProps = {
  height: number;
  width: number | string;
  className?: string;
};

export const LargeCustomSkeleton = () => {
  return (
    <Skeleton className="w-[90%] max-lg:h-[450px] lg:h-[600px] xl:h-[750px] rounded-3xl" />
    // NOTE: for pink add custom-skeleton
  );
};

export const CustomSkeleton = ({
  height,
  width,
  className,
}: CustomSkeletonProps) => {
  return (
    <Skeleton
      height={height}
      width={width}
      className={`custom-skeleton ${className}`}
    />
  );
};
