type StepsProps = {
  steps: { id: number; name: string; duration: string }[];
  orderDate: String;
};
const HorizontalSteps = ({ steps, orderDate }: StepsProps) => {
  return (
    <div className="flex flex-col max-lg:hidden">
      <div className="flex justify-between items-center mb-0 max-xl:mb-0 relative w-full">
        <div className="absolute left-20 max-xl:left-16 top-3.5 h-1 bg-gray-200 z-[1] w-[75%]"></div>
        <div
          className={`absolute left-20 max-xl:left-16 top-3.5 h-1 bg-[#6BBD64] z-[9] w-[18%] max-xl:w-[15%]`}
        ></div>
        {steps.map((step, index) => (
          <div
            key={step.id}
            className="flex flex-col items-center justify-start min-h-20  w-1/4 z-[12]"
          >
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center text-sm mb-2 ${
                index < 1 ? 'bg-[#6BBD64] text-white' : 'bg-gray-200'
              }`}
            >
              {index < 1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    d="M7 20.7132L15 28.7132L31 11.5703"
                    stroke="white"
                    strokeWidth="3.42857"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                index + 1
              )}
            </div>
            <div className="text-sm font-semibold text-center">{step.name}</div>
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center">
        {steps.map((step, index) => (
          <div
            key={step.id}
            className="text-xs text-gray-500 text-center w-1/4"
          >
            {index < 1 ? orderDate : <>{step.duration}</>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalSteps;
