import React from 'react';
// import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import {
  fetchCountFromAirtable,
  updateCountInAirtable,
} from '../components/airtable';
import Loader from '../components/loader';
import ErrorModal from '../components/erromodal';
import PremCardModal from '../components/premCardModal';
import { Certificate } from 'crypto';

const loadRazorpayScript = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const indianStates = [
  { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
  { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
  { value: 'Assam', label: 'Assam' },
  { value: 'Bihar', label: 'Bihar' },
  { value: 'Chhattisgarh', label: 'Chhattisgarh' },
  { value: 'Goa', label: 'Goa' },
  { value: 'Gujarat', label: 'Gujarat' },
  { value: 'Haryana', label: 'Haryana' },
  { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
  { value: 'Jharkhand', label: 'Jharkhand' },
  { value: 'Karnataka', label: 'Karnataka' },
  { value: 'Kerala', label: 'Kerala' },
  { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
  { value: 'Maharashtra', label: 'Maharashtra' },
  { value: 'Manipur', label: 'Manipur' },
  { value: 'Meghalaya', label: 'Meghalaya' },
  { value: 'Mizoram', label: 'Mizoram' },
  { value: 'Nagaland', label: 'Nagaland' },
  { value: 'Odisha', label: 'Odisha' },
  { value: 'Punjab', label: 'Punjab' },
  { value: 'Rajasthan', label: 'Rajasthan' },
  { value: 'Sikkim', label: 'Sikkim' },
  { value: 'Tamil Nadu', label: 'Tamil Nadu' },
  { value: 'Telangana', label: 'Telangana' },
  { value: 'Tripura', label: 'Tripura' },
  { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  { value: 'Uttarakhand', label: 'Uttarakhand' },
  { value: 'West Bengal', label: 'West Bengal' },
  {
    value: 'Andaman and Nicobar Islands',
    label: 'Andaman and Nicobar Islands',
  },
  { value: 'Chandigarh', label: 'Chandigarh' },
  {
    value: 'Dadra and Nagar Haveli and Daman and Diu',
    label: 'Dadra and Nagar Haveli and Daman and Diu',
  },
  { value: 'Lakshadweep', label: 'Lakshadweep' },
  { value: 'Delhi', label: 'Delhi' },
  { value: 'Puducherry', label: 'Puducherry' },
  { value: 'Ladakh', label: 'Ladakh' },
  { value: 'Jammu & Kashmir', label: 'Jammu & Kashmir' },
];

function CheckoutScreen() {
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [emailWarning, setEmailWarning] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [piePrice, setPiePrice] = React.useState(999);
  const [showLoader, setShowLoader] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [address, setAddress] = React.useState('Not Asked');
  const [state, setState] = React.useState<string>('Not Asked');
  const [pincode, setPincode] = React.useState('');
  const [paymentOption, setPaymentOption] = React.useState('999');
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true);
  const [currenPreOrderCount, setCurrenPreOrderCount] = React.useState(2000);
  const [showModal, setShowModal] = React.useState(false);
  const [setBtnLoader, setShowBtnLoader] = React.useState(false);

  const navigate = useNavigate();

  function getFormattedDate(timestamp: number): string {
    const date = new Date(timestamp * 1000);

    const getDaySuffix = (day: number): string => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();
    const daySuffix = getDaySuffix(day);

    // const time = date.toLocaleString('en-US', {
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   hour12: true,
    // });

    // return `${day}${daySuffix} ${month}, ${year} | ${time}`;
    return `${day}${daySuffix} ${month}, ${year}`;
  }

  const options = indianStates.map((state) => ({
    value: state.value,
    label: state.label,
  }));

  const handleChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    if (selectedOption) {
      setState(selectedOption.value);
    } else {
      setState('');
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: '#E5E5E5',
      borderRadius: '8px',
      height: '47px',
      color: '#475467',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#475467',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#475467',
      fontWeight: 400,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: '#475467',
    }),
    value: (provided: any, state: any) => ({
      ...provided,
      color: '#475467',
    }),
  };

  const selectedOption =
    options.find((option) => option.value === state) || null;

  const createOrder = async () => {
    const paymentWebhookAPI =
      'https://kmvxejomsbmklyrgkgzj.supabase.co/functions/v1/pixa-razorpay-webhook';
    const response = await fetch(paymentWebhookAPI, {
      method: 'POST',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imttdnhlam9tc2Jta2x5cmdrZ3pqIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcxMzM0NTc2OSwiZXhwIjoyMDI4OTIxNzY5fQ.7abVoPmLVABLdO-QmQvb8LzCZeP5X8kcOPARfNnh690',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        contact,
        address,
        state,
        pincode,
        amount: piePrice,
        currency: 'INR',
      }),
    });

    if (!response.ok) {
      setErrorMessage('Failed to create order. Please try again.');
      setShowErrorModal(true);
      throw new Error('Failed to create order');
    }

    const orderData = await response.json();
    return orderData;
  };

  // const generateCertificate = async (date: string, name: string, email: string) => {
  //   console.log(date, name, email);
  //   const accredibleAPI =
  //     'https://api.accredible.com/v1/credentials';
  //   const response = await fetch(accredibleAPI, {
  //     method: 'POST',
  //     headers: {
  //       Authorization:
  //       'Token token=2495f0b7600b0f74be2dbeb4fa884110',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       "credential": {
  //         "recipient": {
  //           "name": name,
  //           "email": email
  //         },
  //         "group_id": 618380,
  //         "issued_on": date
  //       }
  //     }),
  //   });

  //   if (!response.ok) {
  //     console.log("failed to generate Birth Certificate", response);
  //     return "https://images.credential.net/embed/g2z2n24g.png";
  //   }

  //   const responseData = await response.json();
  //   return responseData.certificate.image.preview;
  // }

  const updateOrder = async (
    orderId: string,
    transactionId: string,
    generatedCertificateLink: string,
    need_mail: Boolean = true
  ) => {
    const paymentWebhookAPI =
      'https://kmvxejomsbmklyrgkgzj.supabase.co/functions/v1/pixa-razorpay-webhook';
    const response = await fetch(paymentWebhookAPI, {
      method: 'PUT',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imttdnhlam9tc2Jta2x5cmdrZ3pqIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcxMzM0NTc2OSwiZXhwIjoyMDI4OTIxNzY5fQ.7abVoPmLVABLdO-QmQvb8LzCZeP5X8kcOPARfNnh690',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId: orderId,
        transactionId: transactionId,
        certificateLink: generatedCertificateLink,
        need_mail: need_mail,
      }),
    });

    if (!response.ok) {
      setErrorMessage('Failed to update order. Please try again.');
      setShowErrorModal(true);
      throw new Error('Failed to Update order');
    }

    const updatedOrderData = await response.json();
    return updatedOrderData;
  };

  const handleRazorpayPayment = async (
    orderId: string,
    orderAmount: number,
    orderDate: number
  ) => {
    const isScriptLoaded = await loadRazorpayScript();

    if (!isScriptLoaded) {
      setErrorMessage(
        'Failed to laod Razor Pay. Please check your Internet and try again.'
      );
      setShowErrorModal(true);
      return;
    }

    const options = {
      key: 'rzp_live_qIKzKpajywRnlr',
      amount: orderAmount,
      currency: 'INR',
      name: 'Pixa Pie',
      description: 'Purchase Description',
      order_id: orderId,
      handler: async (response: any) => {
        console.log('Payment successful!', response);

        const transactionId = response.razorpay_payment_id;
        const generatedCertificateLink = 'will be uploaded'; // await generateCertificate(getFormattedDate(orderDate), name, email);
        const updatedOrder = await updateOrder(
          orderId,
          transactionId,
          generatedCertificateLink,
          true
        );
        const status = await updateCountInAirtable(currenPreOrderCount - 1);
        if (status && updatedOrder) {
          console.log(
            'Stock count and order Status updated successfully with:',
            orderId,
            getFormattedDate(orderDate),
            updatedOrder
          );
          navigate('/confirm', {
            state: {
              orderId: response.razorpay_order_id,
              orderDate: getFormattedDate(orderDate),
              orderedBy: name,
              certificateLink: transactionId, // will use this in confirm page
            },
          });
        } else {
          alert('Failed to update stock count, please try again.');
          console.log('Failed to update stock count, please try again.');
        }
      },
      prefill: {
        name: name,
        email: email,
        contact: contact,
      },
      theme: {
        color: '#FF6079',
      },
    };

    const rzp = new (window as any).Razorpay(options);
    rzp.open();
  };

  const submitHandler = async () => {
    try {
      setShowBtnLoader(true);
      const orderData = await createOrder();
      console.log('Order created', orderData);

      await handleRazorpayPayment(
        orderData.id,
        orderData.amount,
        orderData.created_at
      );
    } catch (error) {
      setShowBtnLoader(false);
      console.error('Error in order creation/payment:', error);
      setErrorMessage(
        'Failed to place order or process payment. Please try again.'
      );
      setShowErrorModal(true);
    }
  };

  const closeModal = () => {
    setShowErrorModal(false);
    window.scrollTo(0, 0);
    window.location.reload();
  };

  const closePremModal = () => {
    setShowModal(false);
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  React.useEffect(() => {
    setShowLoader(true);

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    if (paymentOption === '999') {
      setPiePrice(999);
    } else if (paymentOption === '4999') {
      setPiePrice(4999);
    } else {
      setPiePrice(999);
    }
    return () => clearTimeout(timer);
  }, [paymentOption]);

  React.useEffect(() => {
    if (
      name &&
      email &&
      contact &&
      address &&
      state &&
      pincode &&
      paymentOption
    ) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  }, [name, email, contact, address, state, pincode, paymentOption]);

  React.useEffect(() => {
    if (email && !validateEmail(email)) {
      setEmailWarning(true);
    } else {
      setEmailWarning(false);
    }
  }, [email]);

  React.useEffect(() => {
    setShowLoader(true);
    const fetchSlotCount = async () => {
      const counts = await fetchCountFromAirtable();
      console.log(counts, 'counts');
      if (counts !== undefined || counts !== null) {
        setShowLoader(false);
        setCurrenPreOrderCount(counts as number);
      } else {
        setShowLoader(false);
        setCurrenPreOrderCount(2000);
      }
    };
    fetchSlotCount();
  }, []);

  return (
    <React.Fragment>
      <div className="flex lg:flex-row max-lg:flex-col-reverse w-full relative">
        {showLoader && (
          <div className="min-h-screen absolute top-0 left-0 min-w-full bg-product flex justify-center items-center text-center z-[99999]">
            <Loader />
          </div>
        )}
        {showErrorModal && (
          <ErrorModal message={errorMessage} onClose={closeModal} />
        )}
        {showModal && <PremCardModal onClose={closePremModal} />}
        <div className="lg:min-h-screen lg:w-[50%] flex flex-col lg:px-16 md:px-12 max-md:px-8 justify-start items-start py-12 bg-product-subs-bg-pattern max-md:bg-none bg-no-repeat bg-cover checkout-bg font-sora">
          <span className="text-[2rem] xxl:text-[2.225rem] max-md:text-[1.5rem] max-sm:text-[1.5rem] max-xs:text-[1.5rem] font-bold">
            Order summary
          </span>
          <div className="flex flex-col justify-start items-start pt-8 text-center w-full">
            <span className="flex flex-row justify-between w-full text-center">
              <div className="flex flex-row justify-start items-start text-center">
                <img
                  src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723733924/pixa-pie/checkout/pie.webp"
                  alt="pie"
                  className="object-cover object-center h-[6.6rem] w-[6.4rem] max-lg:h-[5.2rem] max-lg:w-[5rem] rounded-[1rem]"
                />
                <span className="flex flex-col justify-start items-center text-center ml-4">
                  <p className="text-[1.5rem] max-lg:text-[1rem] font-semibold w-full text-left mb-2">
                    Pie
                  </p>
                  <p className="text-[#667085] text-[1.225rem] max-lg:text-[0.725rem] w-full text-left">
                    AI talking friend
                  </p>
                </span>
              </div>
              <span className="font-semibold text-[2rem] max-lg:text-[1.275rem]">
                ₹{piePrice}
              </span>
            </span>
            <span className="flex flex-row justify-between w-full text-center mt-8">
              <div className="flex flex-row justify-start items-start text-center">
                <img
                  src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723733926/pixa-pie/checkout/prime-subs.jpg"
                  alt="prime subscription"
                  className="object-cover object-center h-[6.6rem] w-[6.4rem] max-lg:h-[5.2rem] max-lg:w-[5rem] rounded-[1rem]"
                />
                <span className="flex flex-col justify-start items-center text-center ml-4">
                  <p className="text-[1.5rem] max-lg:text-[1rem] font-semibold w-full text-left mb-2">
                    Premium subscription
                  </p>
                  <p className="text-[#667085] text-[1.225rem] max-lg:text-[0.725rem] w-full text-left">
                    3 months
                  </p>
                </span>
              </div>
              <span className="font-semibold text-[2rem] max-lg:text-[1.275rem]">
                FREE
                <p className="text-[#667085] line-through text-[1rem] max-lg:text-[0.725rem]">
                  ₹1499
                </p>
              </span>
            </span>
          </div>
          <span className="h-[1px] bg-[#667085] w-full my-8"></span>
          <span className="flex flex-row justify-between w-full items-center text-center my-1">
            <span className="text-[1rem] font-semibold">Subtotal</span>
            <span className="text-[1rem] font-semibold">₹{piePrice}</span>
          </span>
          <span className="flex flex-row justify-between w-full items-center text-center my-1">
            <span className="text-[1rem] font-semibold">Shipping</span>
            <span className="text-[1rem] font-semibold">FREE</span>
          </span>
          <span className="flex flex-row justify-between w-full items-center text-center my-1">
            <span className="text-[1.725rem] font-semibold">Total</span>
            <span className="text-[1.725rem] font-semibold">₹{piePrice}</span>
          </span>
          <span className="mt-8 lg:bg-[#FCFCFD] max-lg:bg-[#f4f4f4] rounded-[1rem] flex flex-col w-full lg:p-4 max-lg:p-4">
            <p className="text-[20px] font-semibold w-full text-left">
              Premium subscription
            </p>
            <p className="text-[14px] w-full text-left mt-1">
              Premium Subscription will allow parents to design educational
              experiences, play unlimited songs & stories
            </p>
            <p
              className="text-[16px] font-[600] text-pink w-full text-left mt-[1rem] flex flex-row no-underline cursor-pointer"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <span className="mr-1">Learn more </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className="mt-0.5 h-[20px] w-[20px] max-md:h-[16px] max-md:w-[16px] max-md:mt-0.5"
              >
                <path
                  d="M9.99479 4.15625L15.8281 9.98958M15.8281 9.98958L9.99479 15.8229M15.8281 9.98958L4.16146 9.98958"
                  stroke="#FF6079"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </p>
          </span>
          <button
            title="payment"
            type="submit"
            onClick={submitHandler}
            disabled={submitButtonDisabled}
            className={`w-full p-4 ${
              submitButtonDisabled ? 'bg-pink/50' : 'bg-pink'
            } shadow-inner shadow-white rounded-[1rem] text-[1.125rem] font-semibold text-white my-12 lg:hidden flex justify-center items-center text-center`}
          >
            {setBtnLoader ? (
              <svg
                className="animate-spin h-8 w-8 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              'Proceed to payment'
            )}
          </button>
          <span className="mt-20 max-lg:mt-12 flex flex-col w-full justify-start items-start max-lg:items-center max-lg:justify-center text-left max-lg:text-center">
            <p className="text-[#475467] text-[1.225rem] font-[600] mb-2">
              For any queries please reach out
            </p>
            <span className="flex flex-row max-xl:flex-col justify-start items-center text-center max-lg:space-y-2">
              <a
                className="flex flex-row justify-start items-center mr-8 no-underline"
                href="mailto:support@heypixa.ai/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5 20.5C4.20435 20.5 3.44129 20.1839 2.87868 19.6213C2.31607 19.0587 2 18.2956 2 17.5V7.5C2 6.70435 2.31607 5.94129 2.87868 5.37868C3.44129 4.81607 4.20435 4.5 5 4.5H19C19.7956 4.5 20.5587 4.81607 21.1213 5.37868C21.6839 5.94129 22 6.70435 22 7.5V17.5C22 18.2956 21.6839 19.0587 21.1213 19.6213C20.5587 20.1839 19.7956 20.5 19 20.5H5ZM7.625 8.72C7.52313 8.63302 7.40483 8.56739 7.27711 8.527C7.1494 8.48662 7.01487 8.47231 6.88151 8.48491C6.74816 8.49752 6.61869 8.53679 6.5008 8.6004C6.38292 8.664 6.27901 8.75064 6.19525 8.85517C6.11149 8.9597 6.04959 9.08 6.01321 9.20891C5.97684 9.33783 5.96673 9.47274 5.9835 9.60564C6.00026 9.73853 6.04356 9.86671 6.11081 9.98255C6.17806 10.0984 6.2679 10.1995 6.375 10.28L10.125 13.281C10.6571 13.707 11.3184 13.9391 12 13.9391C12.6816 13.9391 13.3429 13.707 13.875 13.281L17.625 10.281C17.7276 10.1989 17.813 10.0975 17.8763 9.98237C17.9396 9.8673 17.9797 9.74087 17.9942 9.61031C18.0087 9.47975 17.9973 9.34761 17.9607 9.22145C17.9241 9.09529 17.8631 8.97756 17.781 8.875C17.6989 8.77244 17.5974 8.68705 17.4824 8.6237C17.3673 8.56035 17.2409 8.52029 17.1103 8.50581C16.9797 8.49132 16.8476 8.50269 16.7214 8.53927C16.5953 8.57585 16.4776 8.63692 16.375 8.719L12.625 11.719C12.4476 11.861 12.2272 11.9384 12 11.9384C11.7728 11.9384 11.5524 11.861 11.375 11.719L7.625 8.719V8.72Z"
                    fill="#101828"
                  />
                </svg>
                <p className="text-[#101828] text-lg font-[600] ml-2">
                  support@heypixa.ai
                </p>
              </a>
              <a
                className="flex flex-row justify-start items-center no-underline"
                href="https://wa.link/unq089"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5C10.2328 22.503 8.4966 22.0353 6.97002 21.145L2.00402 22.5L3.35602 17.532C2.46497 16.0049 1.99692 14.268 2.00002 12.5C2.00002 6.977 6.47702 2.5 12 2.5ZM8.59202 7.8L8.39201 7.808C8.26271 7.81691 8.13636 7.85087 8.02002 7.908C7.91159 7.96951 7.81258 8.0463 7.72602 8.136C7.60602 8.249 7.53802 8.347 7.46502 8.442C7.09514 8.9229 6.89599 9.51331 6.89902 10.12C6.90102 10.61 7.02902 11.087 7.22902 11.533C7.63802 12.435 8.31102 13.39 9.19902 14.275C9.41302 14.488 9.62302 14.702 9.84902 14.901C10.9524 15.8724 12.2673 16.573 13.689 16.947L14.257 17.034C14.442 17.044 14.627 17.03 14.813 17.021C15.1042 17.0056 15.3885 16.9268 15.646 16.79C15.7769 16.7223 15.9047 16.6489 16.029 16.57C16.029 16.57 16.0713 16.5413 16.154 16.48C16.289 16.38 16.372 16.309 16.484 16.192C16.568 16.1053 16.638 16.0047 16.694 15.89C16.772 15.727 16.85 15.416 16.882 15.157C16.906 14.959 16.899 14.851 16.896 14.784C16.892 14.677 16.803 14.566 16.706 14.519L16.124 14.258C16.124 14.258 15.254 13.879 14.722 13.637C14.6663 13.6128 14.6067 13.5989 14.546 13.596C14.4776 13.5888 14.4084 13.5965 14.3432 13.6184C14.278 13.6403 14.2182 13.676 14.168 13.723C14.163 13.721 14.096 13.778 13.373 14.654C13.3315 14.7098 13.2744 14.7519 13.2088 14.7751C13.1433 14.7982 13.0723 14.8013 13.005 14.784C12.9398 14.7666 12.876 14.7446 12.814 14.718C12.69 14.666 12.647 14.646 12.562 14.61C11.9879 14.3599 11.4565 14.0215 10.987 13.607C10.861 13.497 10.744 13.377 10.624 13.261C10.2306 12.8842 9.88777 12.458 9.60401 11.993L9.54501 11.898C9.50328 11.8338 9.46905 11.765 9.44302 11.693C9.40502 11.546 9.50402 11.428 9.50402 11.428C9.50402 11.428 9.74702 11.162 9.86002 11.018C9.97002 10.878 10.063 10.742 10.123 10.645C10.241 10.455 10.278 10.26 10.216 10.109C9.93602 9.425 9.64668 8.74467 9.34802 8.068C9.28902 7.934 9.11402 7.838 8.95502 7.819C8.90102 7.81233 8.84702 7.807 8.79302 7.803C8.65874 7.7953 8.52411 7.79663 8.39002 7.807L8.59202 7.8Z"
                    fill="#101828"
                  />
                </svg>
                <p className="text-[#101828] text-lg font-[600] ml-2">
                  +91 821 734 6700
                </p>
              </a>
            </span>
          </span>
        </div>
        <div className="lg:w-1/2 lg:min-h-screen flex flex-col justify-start items-center text-center lg:px-16 md:px-12 max-md:px-8">
          <span className="text-[2.5rem] font-bold w-full text-left mb-8 max-md:hidden">
            Checkout
          </span>
          <div className="flex flex-col w-full">
            <span className="text-[1.5rem] font-bold w-full text-left mb-2">
              Contact
            </span>
            {/* <label className="text-[0.725rem] font-bold text-[#475467] w-full text-left mb-1">
              Name
            </label> */}
            <input
              title="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={16}
              placeholder="Name"
              className="w-full h-12 border-[1px] border-[#E5E5E5] rounded-lg mb-4 p-4"
            />

            {/* <label className="text-[0.725rem] font-bold text-[#475467] w-full text-left mb-1">
              E-mail
            </label> */}
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full h-12 border-[1px] border-[#E5E5E5] rounded-lg mb-2 p-4`}
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              required
            />
            {emailWarning && (
              <span className="text-[0.725rem] text-red-500 w-full text-left mb-4 p-4 py-0">
                * Please enter a valid email
              </span>
            )}

            {/* <label className="text-[0.725rem] font-bold text-[#475467] w-full text-left mb-1">
              Contact <span className="text-[0.5rem]">(WhatsApp number)</span>
            </label> */}
            <input
              placeholder="Contact"
              value={contact}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                setContact(onlyNums);
              }}
              inputMode="numeric"
              className="w-full h-12 border-[1px] border-[#E5E5E5] rounded-lg mb-4 p-4"
            />

            {/* <label className="text-[0.725rem] font-bold text-[#475467] w-full text-left mb-1">
              Address
            </label> */}
            {/* <textarea
              placeholder="Address"
              rows={4}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full border-[1px] border-[#E5E5E5] rounded-lg mb-4 p-4"
            />
            <span className="w-full flex flex-row justify-between items-start text-center space-x-8">
              <label className="font-bold text-[#475467] w-full text-left mb-1">
                <Select
                  options={options}
                  value={selectedOption}
                  onChange={handleChange}
                  styles={customStyles}
                  placeholder="Select State"
                  className="w-full  font-normal border-[1px] rounded-lg"
                />
              </label>

            </span> */}
            <label className="font-bold text-[#475467] w-full text-left mb-1 flex flex-col justify-start items-start">
              {/* Pincode */}
              <input
                placeholder="Pincode"
                value={pincode}
                onChange={(e) => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                  setPincode(onlyNums);
                }}
                inputMode="numeric"
                className="w-full h-12 font-normal border-[1px] border-[#E5E5E5] rounded-lg mb-4 p-4"
              />
            </label>
            <span className="text-[1.5rem] font-bold w-full text-left mt-4">
              Payment
            </span>
            <span className="p-4 border border-[#424242] border-solid rounded-[1rem] mt-2 text-[#475467] font-semibold">
              <div
                title="payment options"
                className="w-full flex justify-start items-start text-left flex-col"
              >
                <label className="flex items-center max-lg:text-[0.825rem]">
                  <input
                    type="radio"
                    name="payment_option"
                    value="999"
                    className="mr-2 mb-4 lg:mb-0 custom-radio"
                    checked={paymentOption === '999'}
                    onChange={() => setPaymentOption('999')}
                  />
                  Prebook at ₹999, Pay rest at the time of delivery
                </label>
                <span className="h-[3px] bg-[#eaecf0] w-full flex my-6"></span>
                <label className="flex items-center max-lg:text-[0.825rem]">
                  <input
                    type="radio"
                    name="payment_option"
                    value="4999"
                    className="mr-2 custom-radio"
                    checked={paymentOption === '4999'}
                    onChange={() => setPaymentOption('4999')}
                  />
                  Pay full now (₹4,999)
                </label>
              </div>
            </span>

            <button
              title="payment"
              type="submit"
              onClick={submitHandler}
              disabled={submitButtonDisabled}
              className={`w-full p-4 ${
                submitButtonDisabled ? 'bg-pink/50' : 'bg-pink'
              } shadow-inner shadow-white rounded-[1rem] text-[1.125rem] font-semibold text-white my-8 mb-16 max-lg:hidden flex justify-center items-center text-center`}
            >
              {setBtnLoader ? (
                <svg
                  className="animate-spin h-8 w-8 text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                'Proceed to payment'
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CheckoutScreen;
