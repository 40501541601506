import { FaLinkedinIn } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { FaXTwitter } from 'react-icons/fa6';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdMail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="bg-footer min-w-full">
      <div className="flex flex-row max-sm:flex-col max-sm:space-y-10 justify-between items-center py-12 xl:px-52 lg:px-48 md:px-24 max-md:px-16 text-center  w-full">
        <div
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723727516/pixa-pie/pixa_light_logo.svg"
            alt="footer Logo"
            className="h-6 md:h-8"
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="text-white text-center text-[24px] flex flex-row space-x-8 my-2">
          <a
            className="no-underline"
            href="https://wa.link/unq089"
            title="whatsapp"
          >
            <IoLogoWhatsapp />
          </a>
          <a
            className="no-underline"
            href="https://www.linkedin.com/company/pixa-ai/"
            title="linkedin"
          >
            <FaLinkedinIn />
          </a>
          <a
            className="no-underline"
            href="mailto:support@heypixa.ai/"
            title="mail"
          >
            <MdMail />
          </a>
          <a
            className="no-underline"
            href="https://www.instagram.com/heypixa.ai/"
            title="instagram"
          >
            <AiFillInstagram />
          </a>
          <a
            className="no-underline"
            href="https://x.com/Pixa_ai"
            title="twitter"
          >
            <FaXTwitter />
          </a>
        </div>
      </div>
      <div className="flex flex-row max-sm:flex-col max-sm:space-y-6 justify-evenly xl:px-52 lg:px-48 md:px-24 max-md:px-8 pb-12 pt-2 cursor-pointer min-w-full items-center text-[20px] text-white font-sora font-[600] w-full">
        <span
          onClick={() => {
            window.open('/privacy-policy', '_blank');
          }}
          className="opacity-50 hover:opacity-100"
        >
          Privacy policy
        </span>
        <span
          onClick={() => {
            window.open('/terms-and-conditions', '_blank');
          }}
          className="opacity-50 hover:opacity-100"
        >
          Terms and conditions
        </span>
        <span
          onClick={() => {
            window.open('/user-support', '_blank');
          }}
          className="opacity-50 hover:opacity-100"
        >
          Data usage support
        </span>
        <span
          onClick={() => {
            window.open('/refund-policy', '_blank');
          }}
          className="opacity-50 hover:opacity-100"
        >
          Refund Policy
        </span>
      </div>
      <div className="pb-8 text-white font-sora w-full justify-center items-center text-center opacity-30 ">
        © 2024 All rights reserved by{' '}
        <span className="md:hidden">
          <br />
        </span>
        Pixaverse Studios Pvt. Ltd.
      </div>
    </div>
  );
}

export default Footer;
