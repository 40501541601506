import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const HeroHeading = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.7,
      },
    },
  };

  const textVariants = {
    hidden: { y: 300, rotate: 15 },
    show: (custom: number) => ({
      y: 0,
      rotate: 0,
      transition: {
        y: {
          type: 'spring',
          stiffness: 100,
          damping: 25,
          duration: 1,
          delay: custom * 0.3,
        },
        rotate: {
          duration: 0.6,
          delay: custom * 0.3,
        },
      },
    }),
  };

  return (
    <div
      className="w-full justify-center items-center text-center flex overflow-hidden"
      ref={ref}
    >
      {inView && (
        <motion.div
          className="xl:w-[calc(100%_-_540px)] lg:w-[80%] max-lg:w-[80%] max-sm:w-[90%] lg:text-[4.5rem] lg:leading-[5rem] max-lg:text-6xl max-md:text-5xl max-sm:text-4xl max-xs:text-3xl font-bold text-[#2F2010] flex flex-col"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <motion.span
            className="min-w-full lg:text-[4.5rem] lg:leading-[5rem] max-lg:text-6xl max-md:text-5xl max-sm:text-4xl max-xs:text-3xl font-bold text-[#2F2010]"
            variants={textVariants}
            custom={0}
            style={{ originX: 0 }}
          >
            We are building real
          </motion.span>
          <motion.span
            className="min-w-full lg:text-[4.5rem] lg:leading-[5rem] max-lg:text-6xl max-md:text-5xl max-sm:text-4xl max-xs:text-3xl font-bold text-[#2F2010]"
            variants={textVariants}
            custom={2}
            style={{ originX: 0 }}
          >
            life toy story...
          </motion.span>
        </motion.div>
      )}
    </div>
  );
};

export default HeroHeading;
