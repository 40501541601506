import { useEffect, memo } from 'react';
import ErrorBoundary from './components/errorBoundary';
import ProductNavbar from './components/productNavbar';
import Footer from './components/footer';
import ConfirmationPage from './screens/confirmationpage';
import { useLocation } from 'react-router-dom';
import Loader from './components/loader';

const Confirm = () => {
  const location = useLocation();
  const orderId = location.state?.orderId;
  const orderDate = location.state?.orderDate;
  const orderedBy = location.state?.orderedBy;
  const certificateLink = location.state?.certificateLink;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-product font-sora flex flex-col items-center overflow-hidden min-h-screen">
      <ErrorBoundary>
        <ProductNavbar />
      </ErrorBoundary>
      <ErrorBoundary>
       {/* <ConfirmationPage orderId={'orderId'} orderDate={'orderDate'} orderedBy={'orderedBy'} />  */}
        {location.state &&
        location.state.orderId &&
        location.state.certificateLink &&
        location.state.orderDate && location.state.orderedBy ? (
          <ConfirmationPage orderId={orderId} orderDate={orderDate} orderedBy={orderedBy} certificateLink={certificateLink} />
        ) : (
          <Loader />
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        <Footer />
      </ErrorBoundary>
    </div>
  );
};

export default memo(Confirm);
