import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const PremiumCardsHeading = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const textVariants = {
    hidden: { y: 300, rotate: 15 },
    show: (custom: number) => ({
      y: 0,
      rotate: 0,
      transition: {
        y: {
          type: 'slide',
          stiffness: 260,
          damping: 20,
          delay: custom * 0.2,
        },
        rotate: {
          duration: 0.5,
          delay: custom * 0.2,
        },
      },
    }),
  };

  return (
    <div
      className="w-full justify-center items-center text-center flex overflow-hidden"
      ref={ref}
    >
      {inView && (
        <motion.div
          className="flex flex-col justify-center items-center text-center font-extrabold text-[#344054] text-[4rem] max-xl:text-[3.5rem] xxl:text-[4.5rem] max-lg:text-[2rem] max-xs:text-[1.425rem] leading-[82px] max-lg:leading-[48px] mb-4 space-y-2"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <motion.span
            variants={textVariants}
            custom={0.5}
            style={{ originX: 0 }}
          >
            <span className="mb-4">Introducing Pixa Plus</span>
          </motion.span>
          {/* <motion.span
            className="flex flex-row text-[4rem] max-xl:text-[3.5rem] xxl:text-[4.5rem] max-lg:text-[2rem] max-xs:text-[1.425rem] leading-none"
            variants={textVariants}
            custom={1.5}
            style={{ originX: 0 }}
          >
            <span className='text-[4rem] max-xl:text-[3.5rem] xxl:text-[4.5rem] max-lg:text-[2rem] max-xs:text-[1.425rem] leading-none'>Your Child's Needs</span>
          </motion.span> */}
        </motion.div>
      )}
    </div>
  );
};

export default PremiumCardsHeading;
