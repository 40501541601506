import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  startTransition,
  memo,
} from 'react';
import Loader from './components/loader';
import ErrorBoundary from './components/errorBoundary';
import Hero from './screens/hero';

const Video = lazy(() => import('./screens/video'));
const Herovideo = lazy(() => import('./screens/herovideo'));
const Videoexp = lazy(() => import('./screens/videoexp'));
const Videofeatures = lazy(() => import('./screens/videofeatures'));
const Animation = lazy(() => import('./screens/animation'));
const Features = lazy(() => import('./screens/features'));
const FeaturesCards = lazy(() => import('./screens/featurescards'));
const CtcCard = lazy(() => import('./components/ctccard'));
// const Testimonial = lazy(() => import('./screens/testimonial'));
const Faq = lazy(() => import('./screens/faq'));
const Footer = lazy(() => import('./components/footer'));

const App: React.FC = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    startTransition(() => {
      setIsContentLoaded(true);
    });
  }, []);

  return (
    <div className="min-h-screen bg-primary font-sora">
      <ErrorBoundary>
        <Hero />
      </ErrorBoundary>
      {isContentLoaded && (
        <Suspense fallback={<Loader />}>
          <ErrorBoundary>
            <Video />
          </ErrorBoundary>
          <ErrorBoundary>
            <Herovideo />
          </ErrorBoundary>
          <ErrorBoundary>
            <Videoexp />
          </ErrorBoundary>
          <ErrorBoundary>
            <Videofeatures />
          </ErrorBoundary>
          <ErrorBoundary>
            <Animation />
          </ErrorBoundary>
          <ErrorBoundary>
            <Features />
          </ErrorBoundary>
          <ErrorBoundary>
            <FeaturesCards />
          </ErrorBoundary>
          <ErrorBoundary>
            <CtcCard />
          </ErrorBoundary>
          {/* <ErrorBoundary>
            <Testimonial />
          </ErrorBoundary> */}
          <ErrorBoundary>
            <Faq />
          </ErrorBoundary>
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        </Suspense>
      )}
    </div>
  );
};

export default memo(App);
