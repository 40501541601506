'use client';
import { useEffect, useState } from 'react';
import { useForm } from '@formspree/react';
import { Footer, Navbar } from '../components';

const Delete = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const key = 'xqkopdvb';

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, handleSubmit] = useForm(key);
  const notifySuccess = () => alert('Form submitted successfully!');

  useEffect(() => {
    if (state.succeeded) {
      notifySuccess();
      setLoading(false);
      setForm({
        name: '',
        email: '',
        message: '',
      });
      setConfirmDelete(false);
    }
    if (state.submitting) {
      setLoading(true);
    }
  }, [state.submitting, state.succeeded]);

  const handleChange = (e: any) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e: any) => {
    setConfirmDelete(e.target.checked);
  };

  window.onload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-product font-sora justify-start items-center text-[16px] max-md:text-[14px] lg:px-16 md:px-8 max-md:px-4 max-md:mt-8 min-w-full text-black">
        <span className="text-7xl max-md:text-4xl max-sm:text-4xl min-w-full flex py-24 max-md:py-20 max-sm:py-12 font-bold justify-center items-center text-center max-sm:leading-10 text-black flex-col">
          Delete my account
          <span className="text-policy text-[24px] max-md:text-[16px] font-[400] py-8 max-md:py-4 xl:w-[55%] lg:w-[65%] md:w-[80%] max-sm:w-full leading-[36px] max-md:leading-6">
            If you wish to delete your account, please fill the form below. Note
            that deleting your account will permanently remove all your data
            from our systems.
          </span>
        </span>
        <div className="flex flex-row max-md:flex-col-reverse justify-center items-center text-center space-x-8 max-md:space-x-0 lg:-mt-20">
          <div className="w-full flex justify-center items-start py-8 max-md:py-2 px-4 max-md:px-0 text-center">
            <div className="bg-[#F4F4F4] rounded-[24px] flex flex-col justify-center items-center text-center w-full py-8 max-md:py-2 px-2 md:px-4 lg:px-16">
              <label className="text-policy text-[16px] text-left w-[100%] flex pt-16 max-md:pt-8 pb-2">
                Name
              </label>
              <input
                type="text"
                title="Name"
                name="name"
                value={form.name}
                onChange={handleChange}
                className="w-[100%] h-[48px] rounded-[8px] px-4"
              />
              <label className="text-policy text-[16px] text-left w-[100%] flex pt-4 pb-2">
                E-mail
              </label>
              <input
                type="email"
                title="E-mail"
                name="email"
                value={form.email}
                onChange={handleChange}
                className="w-[100%] h-[48px] rounded-[8px] px-4"
              />
              <label className="text-policy text-[16px] text-left w-[100%] flex pt-4 pb-2">
                Why you want to delete your account?
              </label>
              <textarea
                title="details"
                name="message"
                value={form.message}
                onChange={handleChange}
                className="w-[100%] h-[120px] rounded-[8px] p-2 px-4"
              ></textarea>
              <div className="flex items-center justify-start w-[100%] pt-6 text-policy text-[13px] px-1 text-left">
                <input
                  type="checkbox"
                  id="confirmDelete"
                  title="confirmDelete"
                  checked={confirmDelete}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                I confirm that I want my account to be deleted and understand
                that this action cannot be undone
              </div>
              <button
                type="submit"
                onClick={() => {
                  if (confirmDelete) {
                    handleSubmit(form);
                  } else {
                    alert('Please confirm account deletion.');
                  }
                }}
                disabled={state.submitting || !confirmDelete}
                className="text-white bg-pink text-[16px] text-center w-[100%] flex justify-center items-center rounded-[16px] my-6 xl:my-16 lg:my-12 p-4"
              >
                {loading ? 'Sending...✈️' : 'Submit'}
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default Delete;
