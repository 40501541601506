import { useEffect, memo } from 'react';
import ErrorBoundary from './components/errorBoundary';
import ProductNavbar from './components/productNavbar';
import Footer from './components/footer';
import CheckoutScreen from './screens/checkoutscreen';

const Checkout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-product font-sora flex flex-col items-center overflow-hidden min-h-screen">
      <ErrorBoundary>
        <ProductNavbar />
      </ErrorBoundary>
      <ErrorBoundary>
        <CheckoutScreen />
      </ErrorBoundary>
      <ErrorBoundary>
        <Footer />
      </ErrorBoundary>
    </div>
  );
};

export default memo(Checkout);
