import React, { useState } from 'react';
import Lottie from 'lottie-react';
import voiceAnimationMain from '../voice.json';
import voicePlayAnimationMain from '../voice_play.json';

function ProductHeroHeading() {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePixaVoice = () => {
    if (!isPlaying) {
      const audio = new Audio(
        'https://res.cloudinary.com/dx6dieevo/video/upload/f_auto,q_auto:good,w_auto/v1723784198/pixa-pie/product/pixa.ogg'
      );

      setIsPlaying(true);
      console.log('Audio is playing');

      audio.addEventListener('ended', () => {
        setIsPlaying(false);
        console.log('Audio ended');
      });

      audio.play().catch((error) => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      });
    } else {
      console.log('Audio is already playing');
    }
  };
  return (
    <div className="flex flex-row justify-start items-center text-center">
      <span className="flex flex-row text-productheader text-5xl xxl:text-7xl xxl:leading-[4.5rem] leading-[3.5rem] max-lg:text-[2rem] font-semibold space-x-2 xxl:space-x-4 items-center">
        <span>Hey, I'm </span>
        <span className='text-pink font-["Sriracha"] text-[3rem] xxl:text-[4.5rem] xxl:leading-[4.5rem] leading-[3.5rem] max-lg:text-[2.25rem]'>
          Pixa!
        </span>
      </span>
      <span
        className={`w-[50px] h-[50px] xxl:w-[70px] xxl:h-[70px]  flex flex-col justify-center items-center ml-[16px] max-lg:w-[45px] max-lg:h-[45px] max-lg:ml-[10px] bg-pink rounded-full object-cover ${
          isPlaying ? 'pl-1.5 cursor-not-allowed' : 'p-2 xxl:p-0 max-lg:p-2'
        }`}
        onClick={handlePixaVoice}
      >
        <Lottie
          animationData={
            isPlaying ? voicePlayAnimationMain : voiceAnimationMain
          }
          loop={true}
          className="hover:scale-125 max-lg:scale-125 transition-transform duration-300 m-0 max-lg:m-0.5"
        />
      </span>
    </div>
  );
}

export default ProductHeroHeading;
