import { motion } from 'framer-motion';

interface ProductfeatcardProps {
  cardDetails: {
    cardImage: string;
    cardTitle: string;
    cardDescription: string;
    prevcardImage: string;
    prevTitle: string;
    secondPrevcardImage: string;
    secondPrevTitle: string;
    nextCardImage: string;
    nextTitle: string;
    secondNextCardImage: string;
    secondNextTitle: string;
  };
  index: number;
}

function Productfeatcard({ cardDetails, index }: ProductfeatcardProps) {
  return (
    <div
      className={`h-[650px] w-[90%] max-md:w-[90%] flex flex-col justify-start items-start space-y-12 max-md:space-y-6 max-md:mt-10 mb-16 max-md:mb-10 ${
        index === 0 ? '' : ''
      } `}
    >
      {/* second prev card */}
      <div
        className={`bg-[#E9E9E9] text-pink flex flex-row justify-start items-center text-center rounded-[18px] -rotate-[10deg] px-6 max-md:px-4 p-4 max-md:p-4 ml-4 max-md:ml-6 space-x-1
         ${index === 1 || index === 0 ? 'opacity-0' : 'opacity-50'}
        `}
      >
        <span
          className="w-[32px] h-[32px] max-md:w-[24px] max-md:h-[24px] bg-100"
          style={{
            backgroundImage: `url('${cardDetails.secondPrevcardImage}')`,
          }}
        ></span>
        <span className="text-[1.5rem] max-md:text-[1rem] font-semibold">
          {cardDetails.secondPrevTitle}
        </span>
      </div>
      {/* prev card */}
      <div
        className={`bg-[#E9E9E9] text-pink flex flex-row justify-start items-center text-center rounded-[18px] -rotate-[5deg] px-6 max-md:px-4 p-4 max-md:p-4 ml-12 max-md:ml-6  space-x-1 ${
          index === 0 ? 'opacity-0' : 'opacity-80'
        }`}
      >
        <span
          className="w-[32px] h-[32px] max-md:w-[24px] max-md:h-[24px] bg-100"
          style={{
            backgroundImage: `url('${cardDetails.prevcardImage}')`,
          }}
        ></span>
        <span className="text-[1.5rem] max-md:text-[1rem] font-semibold">
          {cardDetails.prevTitle}
        </span>
      </div>
      {/* pink card */}
      <motion.div className="w-full flex flex-row justify-between items-center text-center space-x-4 max-md:space-x-2">
        <div className="w-10 h-10 max-md:h-6 max-md:w-6 flex flex-col justify-center items-center px-2">
          <span className="bg-pink h-6 w-6 max-md:h-6 max-md:w-8 rounded-[100%]"></span>
        </div>
        <div className="flex flex-col justify-start items-start text-center p-8 max-md:p-4 bg-pink rounded-[1rem] space-y-2 max-md:space-y-0">
          <span className="flex flex-row justify-start items-center text-white text-left">
            <span
              className="max-xl:min-w-[50px] xl:min-w-[96px] max-md:min-h-[36px] max-xl:min-h-[50px] xl:min-h-[96px] max-md:min-w-[36px] mr-4 max-md:mr-2 bg-white text-pink rounded-[12px] bg-no-repeat bg-bottom-center bg-100"
              style={{
                backgroundImage: `url('${cardDetails.cardImage}')`,
              }}
            ></span>
            <span className="font-semibold xl:text-[2.5rem] max-md:text-[1.125rem] xl:leading-[3rem] max-xl:text-[1.5rem] max-lg:leading-[2rem]  max-md:leading-[1.25rem]">
              {cardDetails.cardTitle}
            </span>
          </span>
          <span className="font-[400] w-full text-white text-left xl:text-[1.25rem] max-md:text-[0.875rem] max-xl:text-[1rem] xl:leading-[1.5rem] max-xl:leading-[1.25rem] max-md:leading-[1rem] pt-2">
            {cardDetails.cardDescription}
          </span>
        </div>
      </motion.div>
      {/* next card */}
      <div
        className={`bg-[#E9E9E9] flex flex-row justify-start items-center text-center rounded-[18px] rotate-[5deg] px-6 max-md:px-4 p-4 max-md:p-4 ml-12 max-md:ml-6 space-x-1
        ${
          cardDetails.nextTitle === 'Generates and plays music realtime'
            ? 'opacity-0'
            : 'opacity-80'
        }
      `}
      >
        <span
          className="w-[32px] h-[32px] max-md:w-[24px] max-md:h-[24px] bg-100"
          style={{
            backgroundImage: `url('${cardDetails.nextCardImage}')`,
          }}
        ></span>
        <span className="text-[1.5rem] max-md:text-[1rem] text-[#424242] font-semibold">
          {cardDetails.nextTitle}
        </span>
      </div>
      {/* second next card */}
      <div
        className={`bg-[#E9E9E9] flex flex-row justify-start items-center text-center rounded-[18px] rotate-[10deg] px-6 max-md:px-4 p-4 max-md:p-4 ml-4 max-md:ml-6 space-x-1
        ${
          cardDetails.secondNextTitle ===
            'Generates and plays music realtime' ||
          cardDetails.secondNextTitle === 'soemthing that should not be visible'
            ? 'opacity-0'
            : 'opacity-50'
        }
        `}
      >
        <span
          className="w-[32px] h-[32px] max-md:w-[24px] max-md:h-[24px] bg-100"
          style={{
            backgroundImage: `url('${cardDetails.secondNextCardImage}')`,
          }}
        ></span>
        <span className="text-[1.5rem] max-md:text-[1rem] text-[#424242] font-semibold">
          {cardDetails.secondNextTitle}
        </span>
      </div>
    </div>
  );
}

export default Productfeatcard;
