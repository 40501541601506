import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ProductDemoHeading = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.7,
      },
    },
  };

  const textVariants = {
    hidden: { y: 300, rotate: 15 },
    show: (custom: number) => ({
      y: 0,
      rotate: 0,
      transition: {
        y: {
          type: 'spring',
          stiffness: 100,
          damping: 25,
          duration: 1,
          delay: custom * 0.3,
        },
        rotate: {
          duration: 0.6,
          delay: custom * 0.3,
        },
      },
    }),
  };

  return (
    <div
      className="w-full justify-center items-center text-center flex overflow-hidden"
      ref={ref}
    >
      {inView && (
        <motion.div
          className="flex flex-col"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <motion.span
            className="w-full flex justify-center items-center text-center "
            variants={textVariants}
            custom={0.5}
            style={{ originX: 0 }}
          >
            <span className="font-extrabold text-[4rem] xxl:text-[4.5rem] leading-[4.5rem] max-md:text-[2rem] max-md:leading-[2.5rem] max-xs:text-[2rem] text-[#424242]">
              One app for every action
            </span>
          </motion.span>
        </motion.div>
      )}
    </div>
  );
};

export default ProductDemoHeading;
