import React, { useEffect, useState } from 'react';

function IosAssetLinksComponent() {
  const [assetLinks, setAssetLinks] = useState(null);

  useEffect(() => {
    fetch('/.well-known/apple-app-site-association.json')
      .then((response) => response.json())
      .then((data) => setAssetLinks(data))
      .catch((error) => console.error('Error fetching asset links:', error));
  }, []);

  if (!assetLinks) {
    return <div>Loading...</div>;
  }

  const rawJsonString = JSON.stringify(assetLinks, null, 2);

  return (
    <div>
      <pre>{rawJsonString}</pre>
    </div>
  );
}

export default IosAssetLinksComponent;
