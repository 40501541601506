import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductHerofeatCards from '../components/productHerofeatCards';
import ProductHeroHeading from '../components/productHeroHeading';
import 'react-loading-skeleton/dist/skeleton.css';
import { TruckSVG } from '../components/productherosvg';
import { fetchCountFromAirtable } from '../components/airtable';
import {
  CustomSkeleton,
  LargeCustomSkeleton,
} from '../components/customSkeleton';

const ProductHero = () => {
  const [preOrderLeft, setPreOrderLeft] = useState(1000);
  const [displayedCard, setDisplayedCard] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const totalCards = 5;
  const navigate = useNavigate();

  const getWidthAsPreOrderLeft = (preOrders: number) => {
    const width = (preOrders / 1000) * 100;
    return width;
  };

  const nextCard = useCallback(() => {
    setDisplayedCard(
      (prevDisplayedCard) => (prevDisplayedCard + 1) % totalCards
    );
    setLoaded(false);
  }, [totalCards]);

  const prevCard = useCallback(() => {
    setDisplayedCard(
      (prevDisplayedCard) => (prevDisplayedCard - 1 + totalCards) % totalCards
    );
    setLoaded(false);
  }, [totalCards]);

  const displayedCardUrls = React.useMemo(
    () => [
      'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723730958/pixa-pie/product/product_1.webp',
      'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723730959/pixa-pie/product/product_2.webp',
      'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723730961/pixa-pie/product/product_3.webp',
      'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723730963/pixa-pie/product/product_4.webp',
      'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723730957/pixa-pie/product/product_5.webp',
    ],
    []
  );

  useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = `${displayedCardUrls[displayedCard]}`;
    imageLoader.onload = () => setLoaded(true);
  }, [displayedCard, displayedCardUrls]);

  useEffect(() => {
    const fetchSlotCount = async () => {
      const counts = await fetchCountFromAirtable();
      console.log(counts, 'counts');
      if (counts !== undefined || counts !== null) {
        setPreOrderLeft(counts as number);
      } else {
        setPreOrderLeft(1000);
      }
    };
    fetchSlotCount();
  }, []);
  return (
    <div className="w-full flex justify-center items-start text-center mb-12 max-lg:mb-0 max-lg:min-h-0">
      <div className="w-[90%] flex flex-row max-lg:flex-col justify-start max-lg:justify-center max-lg:items-center">
        <div className="w-[45%] max-lg:min-w-full h-min xxl:h-[calc(100vh_-_50px)] flex flex-col justify-start max-lg:justify-center items-center text-center relative px-8 max-lg:px-0">
          <div className="w-full h-full xxl:min-h-max relative max-lg:mb-8 min-h-[350px]">
            {loaded ? (
              <img
                src={`${displayedCardUrls[displayedCard]}`}
                alt="Product"
                className="w-full h-full xxl:h-min object-contain xxl:object-cover rounded-3xl object-top"
                onLoad={() => setLoaded(true)}
              />
            ) : (
              <LargeCustomSkeleton />
            )}
            <div className="absolute inset-0 flex justify-between items-center p-4 max-lg:p-1 bg-transparent w-full h-full z-[999] hover:opacity-100 lg:opacity-0 max-lg:opacity-100">
              <span
                className="h-[50px] w-[50px] max-lg:h-[40px] max-lg:w-[40px] bg-no-repeat bg-product-left-icon-pattern bg-100"
                onClick={prevCard}
              ></span>
              <span
                className="h-[50px] w-[50px] max-lg:h-[40px] max-lg:w-[40px] bg-no-repeat bg-product-right-icon-pattern bg-100"
                onClick={nextCard}
              ></span>
            </div>
          </div>
          <div className="w-full flex flex-row justify-center items-center text-[1.5rem] font-bold max-lg:hidden">
            <div className="w-full flex space-x-4 max-lg:space-x-2 justify-between overflow-hidden mx-0 my-8">
              {displayedCardUrls.map((url, index) => (
                <img
                  key={url}
                  onClick={() => setDisplayedCard(index)}
                  src={`${url}`}
                  alt={`Product ${index + 1}`}
                  className={`h-[7vw] w-[6vw] max-w-[150px] object-fill rounded-2xl object-center border-[3px] border-solid ${
                    displayedCard === index
                      ? 'border-black'
                      : 'border-[#D0D5DD]'
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="w-[55%] max-lg:w-full flex flex-col justify-start items-start max-lg:justify-center max-lg:items-start">
          <ProductHeroHeading />
          {/* <span className="text-[#475467] text-[1.5rem] xxl:text-3xl max-md:text-[1rem] font-semibold mb-4 xxl:mb-6 xxl:mt-1">
            AI powered companion
          </span> */}
          <span className="text-left text-[1rem] xxl:text-[1.275rem] max-lg::w-[90%] max-lg:text-[0.725rem] text-[#6E6D6D] pr-0 max-lg:pr-0 font-[400] mt-4 xxl:mt-6 ">
            Our AI-powered toys, have the ability to speak, chat, and listen to
            your voice, all while possessing a profound understanding of
            everything about you.
          </span>
          <p className="relative h-[1rem] xxl:h-[2rem] w-[40%] max-md:w-[35%] xxl:w-[35%] mt-6 mb-4 max-md:mb-4 max-md:mt-4">
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724674948/pixa-pie/okrjvsxk6fim4yltcjsg.png"
              alt="offer"
              className="object-fill absolute z-0 xxl:w-[12rem]"
            />
            <span className="absolute z-10 text-[1rem] xxl:text-[1.225rem] font-[500] max-md:text-[0.8rem] flex text-left text-white left-2 top-1">
              Early bird price
            </span>
          </p>
          <span className="text-[#101828] font-bold text-[2rem] xxl:text-[2.725rem] max-md:text-[1.5rem] mb-4 max-md:mb-2 ">
            ₹4,999
          </span>
          <ProductHerofeatCards />
          {/* <span className="text-[#101828] font-extrabold text-[1.5rem] max-md:text-[1.5rem] xxl:text-[2.225rem] max-md:hidden mb-4 max-md:mb-2 flex text-left flex-col">
            Pre-order opens now!
            <p className="text-[0.825rem] xxl:text-[1.275rem] text-[#6E6D6D] font-normal mt-1">
              Book your pixa with a small deposit now and pay the rest on
              delivery.
            </p>
          </span>
          <div className="w-full flex flex-col justify-center items-center mb-10 max-md:mb-0 bg-[#FCF0F2] pt-[1.75rem] pb-[1.5rem] px-[1.25rem] rounded-[1rem] max-md:mt-4 max-md:pt-[1.5rem] max-md:pb-[1.25rem]">
            {preOrderLeft === 1000 ? (
              <>
                <CustomSkeleton height={20} width={150} />
                <CustomSkeleton height={10} width="99%" className="mt-2" />
                <CustomSkeleton
                  height={50}
                  width="100%"
                  className="mt-8 mb-2"
                />
                <CustomSkeleton height={20} width={200} className="mt-4" />
              </>
            ) : (
              <>
                <p className="text-[1rem] xxl:text-[1.275rem] text-[#101828] font-semibold mt-1 flex text-left w-full mb-2">
                  Pre-orders left:{' '}
                  <span className="font-bold text-pink ml-1 flex flex-row justify-start items-center text-center">
                    {preOrderLeft === 1000 ? (
                      <svg
                        className="animate-spin h-3 w-3 text-pink"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <span className="mt-[1px]">{preOrderLeft}</span>
                    )}
                    <span className="ml-1">out of 1000</span>
                  </span>
                </p>
                <span className="bg-[#f9aeb9] h-[0.725rem] w-[99%] rounded-xl flex justify-start items-center text-left">
                  <span
                    className={`bg-pink h-[0.725rem] rounded-xl shadow-inner shadow-white/30`}
                    style={{
                      width: `${getWidthAsPreOrderLeft(preOrderLeft)}%`,
                    }}
                  ></span>
                </span>
                <button
                  type="submit"
                  title="submit"
                  onClick={() => {
                    navigate('/checkout');
                  }}
                  className="w-[100%] max-md:w-full text-white bg-pink lg:p-4 xl:p-6 max-md:p-4 md:p-4 text-[1.5rem] xxl:text-[1.725rem] xxl:leading-[2.5rem] leading-[1.5rem] max-md:text-[1rem] rounded-2xl mt-8 mb-2 mx-2 font-semibold shadow-inner shadow-white"
                >
                  Pre-order now at ₹999
                </button>
                <span className="flex flex-row justify-center items-center space-x-1 text-[0.9rem] xxl:text-[1.2rem] max-sm:text-[0.625rem]">
                  <span className="flex justify-start items-center h-full">
                    <TruckSVG />
                  </span>
                  <span className="w-full text-left">
                    Delivery starts from 1st week of December
                  </span>
                </span>
              </>
            )}
          </div> */}
          <button
            type="submit"
            title="submit"
            disabled
            // onClick={() => {
            //   navigate('/checkout');
            // }}
            className="w-[100%] max-md:w-full text-white bg-pink lg:p-4 xl:p-6 max-md:p-4 md:p-4 text-[1.5rem] xxl:text-[1.725rem] xxl:leading-[2.5rem] leading-[1.5rem] max-md:text-[1rem] rounded-2xl mt-4 max-md:mt-4 mb-2 font-semibold shadow-inner shadow-white"
          >
            Coming soon...
          </button>
          {/* <button
            type="submit"
            title="submit"
            onClick={() => {
              navigate('/checkout');
            }}
            className="w-[100%] max-md:w-full text-white bg-pink lg:p-4 xl:p-6 max-md:p-4 md:p-4 text-[1.5rem] xxl:text-[1.725rem] xxl:leading-[2.5rem] leading-[1.5rem] max-md:text-[1rem] rounded-2xl mt-4 max-md:mt-4 mb-2 font-semibold shadow-inner shadow-white"
          >
            Pre-order now at ₹999
          </button> */}
          {/* <span className="w-full flex flex-row justify-center items-center text-center">
            <TruckSVG />
            <p className='ml-1 text-[0.9rem] xxl:text-[1.2rem] max-sm:text-[0.625rem] max-md:mt-0.5'>Delivery starts from 1st week of December</p>
          </span> */}
          <div className="border border-[#667085] rounded-[1rem] border-solid flex flex-col justify-center items-start px-4 py-4 mt-2">
            <span className="w-full text-left text-[#101828] font-extrabold text-[1.5rem] max-md:text-[1.5rem] xxl:text-[2.225rem]">
              Oops! We're Sold Out!
            </span>
            <span className="w-full text-left text-[1rem] xxl:text-[1.275rem] text-[#6E6D6D] font-normal mt-1">
              Our first batch sold out in record time due to amazing support.
              We're working hard to restock soon.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHero;
