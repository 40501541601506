import Airtable from 'airtable';

const base = new Airtable({
  apiKey:
    'patvp5JHKjdv6EVOH.31f252fb3602341169793827b4b976abb5532822679bd5566a98b308c9c3790c',
}).base('app1eFhBjnqA9retN');

const fetchCountFromAirtable = async () => {
    try {
      const records = await base('pixa-count')
        .select({ fields: ['COUNT'] })
        .all();
      const counts = records.map((record) => record.get('COUNT'));
      return counts[2];
    } catch (error) {
      console.error('Error fetching counts from Airtable:', error);
      return [];
    }
  };
  
  const updateCountInAirtable = async (count: number) => {
    try {
      await base('pixa-count').update([
        {
          id: 'reckj148lAd1mVBmS',
          fields: {
            COUNT: count,
          },
        },
      ]);
      console.log('Count updated in Airtable successfully!');
      return true;
    } catch (error) {
      console.error('Error updating count in Airtable:', error);
      return false;
    }
  }
  
  export { fetchCountFromAirtable, updateCountInAirtable };