import { Routes, Route } from 'react-router-dom';
import AssetLinksComponent from './screens/asset';
import IosAssetLinksComponent from './screens/iosasset';
import App from './App';
import Product from './Product';
import PrivacyPolicy from './screens/privacypolicy';
import Support from './screens/support';
import Terms from './screens/terms';
import Delete from './screens/delete';
import Checkout from './Checkout';
import RefundPolicy from './screens/refundpolicy';
import Confirm from './Confirm';

const AllRoutes = () => {
  return (
    <div className="overflow-x-hidden">
      <main className="min-h-screen">
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/product" element={<Product />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/user-support" element={<Support />} />
          <Route path="/delete-my-account" element={<Delete />} />
          <Route
            path="/.well-known/apple-app-site-association"
            element={<IosAssetLinksComponent />}
          />
          <Route
            path="/.well-known/assetlinks.json"
            element={<AssetLinksComponent />}
          />
        </Routes>
      </main>
    </div>
  );
};

export default AllRoutes;
