import React, { Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import Navbar from '../components/navbar';
import HeroHeading from '../components/heroHeading';

const Hero: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="relative z-[100] hero-grad overflow-hidden">
      <img
        src={
          'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723728349/pixa-pie/hero/hero_bg_web.webp'
        }
        alt="hero bg"
        className="absolute bottom-0 left-0 min-w-full object-contain z-[-1] lg:mb-[-40px] max-xs:hidden"
        style={{ objectPosition: 'bottom center' }}
      />
      <img
        src={
          'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723728411/pixa-pie/hero/hero_bg_mobile.webp'
        }
        alt="hero bg"
        className="h-full min-w-full object-fill object-top absolute top-0 left-0 z-[-1] xs:hidden"
      />
      <div className="flex flex-col justify-center min-h-screen overflow-hidden">
        <Navbar />
        <div className="flex flex-col justify-start items-center text-center md:-mt-[10rem] max-sm:-mt-32">
          <HeroHeading />
          <p className="xl:w-[calc(100%_-_540px)] lg:w-[90%] max-lg:w-[80%] max-sm:w-[90%] max-xs:w-[90%] lg:text-[1.5rem] lg:leading-[1.75rem] md:text-2xl max-md:text-[16px] max-xs:text-[16px] pb-[3rem] pt-[1.5rem] text-[#5F3C13]">
            World's first AI toys that play, learn, and grow with you.
          </p>
          <motion.button
            title="product page"
            type="submit"
            onClick={() => {
              navigate('/product');
            }}
            className="rounded-[1rem] shadow-inner border-t-2 border-l-2 border-white/30 shadow-white/30 p-4 bg-[#241A0E] text-white px-8 xxl:text-[1.5rem]"
          >
            Meet your new best friends
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Hero);
