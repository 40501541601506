// import { motion } from 'framer-motion';
import ProductfeatHeading from '../components/productFeatHeading';
import ProductfeatAnimation from '../components/productfeatanimation';
import { useInView } from 'react-intersection-observer';
// import { productFeatCardDeatils } from '../cont';
import React from 'react';

function Productfeat() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
  return (
    <div
      className="min-h-screen max-lg:min-h-full max-lg:h-full h-screen relative overflow-hidden max-lg:overflow-visible min-w-full flex flex-row max-lg:flex-col max-lg:space-y-0 product-scrollbar justify-center items-center py-8 max-lg:py-8 max-xl:hidden"
      ref={ref}
    >
      <div className="absolute min-h-[10vh] min-w-full bg-transparent left-0 top-0 z-[99999]"></div>
      <div className="h-[calc(100vh_-_50px)] max-lg:h-full w-full flex flex-row max-lg:flex-col max-lg:justify-center max-lg:items-center justify-end items-end absolute max-lg:relative left-0 top-0  max-lg:space-x-0 overflow-clip z-[1]">
        <div className="max-lg:relative max-lg:h-[350px] w-full flex justify-center items-start text-center">
          <ProductfeatHeading isVisible={inView} />
          <img
            src={
              'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723808007/pixa-pie/product-features/features_sideview.webp'
            }
            alt="feat"
            className="w-[45%] max-lg:w-[80%] h-[100%] xxl:h-[100%] z-[100] bottom-0 -left-24 max-xl:-left-16 absolute bg-no-repeat max-xl:object-fit xl:object-contain object-bottom"
          ></img>
        </div>
      </div>
      <div className="absolute relativeleft-0 w-full h-screen z-[100]">
        <ProductfeatAnimation />
      </div>
    </div>
  );
}

export default Productfeat;

/***
   <div className="lg:hidden w-[90%] h-full flex flex-col justify-start items-start">
        <div
          className={`h-full w-full flex flex-col justify-start items-start space-y-12`}
        >
          <div
            className={`bg-[#E9E9E9] text-pink flex flex-row justify-start items-center text-center rounded-[18px] -rotate-[10deg] px-6 max-lg:px-4 p-4 max-lg:p-4 ml-0 space-x-1 opacity-50`}
          >
            <span
              className="w-[32px] h-[32px] max-lg:w-[24px] max-lg:h-[24px] bg-100"
              style={{
                backgroundImage: `url('${productFeatCardDeatils[3].secondPrevcardImage}')`,
              }}
            ></span>
            <span className="text-[1.5rem] max-lg:max-lg:text-[0.875rem] font-semibold">
              {productFeatCardDeatils[3].secondPrevTitle}
            </span>
          </div>
            className={`bg-[#E9E9E9] text-pink flex flex-row justify-start items-center text-center rounded-[18px] -rotate-[5deg] px-6 max-lg:px-4 p-4 max-lg:p-4 ml-12 max-lg:ml-6 space-x-1 opacity-80`}
          >
            <span
              className="w-[32px] h-[32px] max-lg:w-[24px] max-lg:h-[24px] bg-100"
              style={{
                backgroundImage: `url('${productFeatCardDeatils[3].prevcardImage}')`,
              }}
            ></span>
            <span className="text-[1.5rem] max-lg:max-lg:text-[0.875rem] font-semibold">
              {productFeatCardDeatils[3].prevTitle}
            </span>
          </div>
          <motion.div className="w-full flex flex-row justify-between items-center text-center space-x-4 max-lg:space-x-2">
            <div className="w-10 h-10 max-lg:h-6 max-lg:w-6 flex flex-col justify-center items-center px-2">
              <span className="bg-pink h-6 w-6 rounded-[100%]"></span>
            </div>
            <div className="flex flex-col justify-start items-start text-center p-8 max-lg:p-4 bg-pink rounded-[1rem] space-y-2 max-lg:space-y-0">
              <span className="flex flex-row justify-start items-center text-white text-left">
                <span
                  className="min-w-[56px] min-h-[56px] mr-4 max-lg:mr-2 bg-white text-pink rounded-[12px] bg-no-repeat bg-100 bg-bottom-center"
                  style={{
                    backgroundImage: `url('${productFeatCardDeatils[3].cardImage}')`,
                  }}
                ></span>
                <span className="font-semibold text-[2.5rem] max-lg:text-[1.125rem] leading-[3rem] max-lg:leading-[1.725rem]">
                  {productFeatCardDeatils[3].cardTitle}
                </span>
              </span>
              <span className="font-[400] w-full text-white text-left text-[1.25rem] max-lg:text-[0.875rem] leading-[1.5rem] max-lg:leading-[1rem] pt-2">
                {productFeatCardDeatils[3].cardDescription}
              </span>
            </div>
          </motion.div>
          <div
            className={`bg-[#E9E9E9] flex flex-row justify-start items-center text-center rounded-[18px] rotate-[5deg] px-6 max-lg:px-4 p-4 max-lg:p-4 ml-12 max-lg:ml-6 space-x-1 opacity-80`}
          >
            <span
              className="w-[32px] h-[32px] max-lg:w-[24px] max-lg:h-[24px] bg-100"
              style={{
                backgroundImage: `url('${productFeatCardDeatils[3].nextCardImage}')`,
              }}
            ></span>
            <span className="text-[1.5rem] w-full max-lg:text-[0.875rem] text-[#424242] font-semibold">
              {productFeatCardDeatils[3].nextTitle}
            </span>
          </div>
          <div
            className={`bg-[#E9E9E9] flex flex-row justify-start items-center text-center rounded-[18px] rotate-[10deg] px-6 max-lg:px-4 p-4 max-lg:p-4 ml-0 space-x-1 opacity-50`}
          >
            <span
              className="w-[32px] h-[32px] max-lg:w-[24px] max-lg:h-[24px] bg-100"
              style={{
                backgroundImage: `url('${productFeatCardDeatils[3].secondNextCardImage}')`,
              }}
            ></span>
            <span className="text-[1.5rem] max-lg:max-lg:text-[0.875rem] text-[#424242] font-semibold">
              {productFeatCardDeatils[3].secondNextTitle}
            </span>
          </div>
        </div>
      </div> 
*/
