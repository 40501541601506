import React from 'react';
import PremiumCardsHeading from '../components/premiumSubsHeading';
import { TickSVg, WhiteTickSVg } from '../components/productherosvg';

function PremiumCards() {
  return (
    <div className="bg-product w-full p-8 pt-16 max-md:pt-12 flex justify-center items-center flex-col">
      <PremiumCardsHeading />
      <div className="prem flex flex-row justify-center items-center text-center rounded-[2rem] p-8 mt-4 mb-16">
        <div className="bg-[#FFF] flex flex-col justify-start items-start h-full w-1/2 prem-left p-8 rounded-l-[1rem]">
          <span className="flex flex-row justify-start items-center w-full text-left">
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723733926/pixa-pie/checkout/prime-subs.jpg"
              alt="prime subscription"
              className="object-cover object-center h-[4.0rem] w-[3.8rem] max-lg:h-[4.0rem] max-lg:w-[3.8rem] max-xs:w-[32px] max-xs:h-[32px]  rounded-[1rem] max-xs:rounded-[0.5rem]"
            />
            <span className="flex flex-col ml-2">
              <span className="text-2xl font-[700] text-[#475467] max-xs:text-[16px] max-xs:leading-none">
                Basic
              </span>
              <span className="text-xs text-[#667085] font-normal max-xs:text-[8px]">
                Included with the AI Talking Toy
              </span>
            </span>
          </span>
          <span className="text-4xl text-[#101828] font-[700] w-full text-left mt-8 max-xs:mt-[16px] max-xs:mb-[8px] max-xs:text-[20px]">
            Free
          </span>
          <span className="h-[3px] rounded-[1rem] bg-[#D0D5DD] w-[100%] my-4 mb-8 max-xs:mb-[8px] max-xs:mt-0"></span>
          <div className="flex flex-col justify-start items-start text-left space-y-4 max-xs:space-y-2">
            <span className="flex flex-row justify-start w-full items-center text-left">
              <TickSVg />
              <span className="text-xs text-[#1D2939] font-[600] ml-2">
                Natural conversations
              </span>
            </span>
            <span className="flex flex-row justify-start w-full items-center text-left">
              <TickSVg />
              <span className="text-xs text-[#1D2939] font-[600] ml-2">
                Playful Learning
              </span>
            </span>
            <span className="flex flex-row justify-start w-full items-center text-left">
              <TickSVg />
              <span className="text-xs text-[#1D2939] font-[600] ml-2">
                Plays Music
              </span>
            </span>
            <span className="flex flex-row justify-start w-full items-center text-left">
              <TickSVg />
              <span className="text-xs text-[#1D2939] font-[600] ml-2">
                Shares stories
              </span>
            </span>
          </div>
        </div>
        <div className="prem-card-bg flex flex-col justify-start items-start h-full w-1/2 prem-right p-8 rounded-r-[1rem] relative overflow-hidden">
          <span className="bg-[#FED16E] w-full absolute right-[-38%] top-[5%] flex justify-center items-center text-center text-xs font-[700] text-[#4D390B] rotate-[39.588deg] p-2 shadow-inner shadow-white/50">
            3 months free <br /> with Preorder
          </span>
          <span className="flex flex-row justify-start items-center w-full text-left">
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724348699/pixa-pie/product-premium/nrbqyab0sii53am8nody.webp"
              alt="prime subscription"
              className="object-cover object-center h-[4.0rem] w-[3.8rem] max-lg:h-[4.0rem] max-lg:w-[3.8rem] max-xs:w-[32px] max-xs:h-[32px] rounded-[1rem] max-xs:rounded-[0.5rem]"
            />
            <span className="flex flex-col ml-2">
              <span className="text-2xl font-[700] text-white max-xs:leading-none max-xs:text-[16px]">
                Premium
              </span>
              <span className="text-xs text-white font-normal max-xs:text-[8px]">
                Unlock premium experiences
              </span>
            </span>
          </span>
          <span className="text-4xl text-white font-[700] w-full text-left mt-8 flex flex-row justify-start items-center max-xs:mt-[16px] max-xs:mb-[8px] max-xs:text-[20px]">
            <span>₹499</span>
            <span className="text-lg max-xs:text-[12px] text-white font-normal">
              /month
            </span>
          </span>
          <span className="h-[3px] rounded-[1rem] bg-[#D0D5DD] w-[100%] my-4 mb-6 max-xs:mb-[8px] max-xs:mt-0"></span>
          <span className="text-left text-white w-full text-xs font-[600]">
            Everything in basic +
          </span>
          <div className="flex flex-col justify-start items-start text-left space-y-4 text-white mt-4">
            <span className="flex flex-row justify-start w-full items-center text-left">
              <WhiteTickSVg />
              <span className="text-xs font-[600] ml-2">
                Customize your kids’ educational journey
              </span>
            </span>
            <span className="flex flex-row justify-start w-full items-center text-left">
              <WhiteTickSVg />
              <span className="text-xs font-[600] ml-2">
                Insights: What your kid is talking to Pixa!
              </span>
            </span>
            <span className="flex flex-row justify-start w-full items-center text-left">
              <WhiteTickSVg />
              <span className="text-xs font-[600] ml-2">
                On-demand song and story generation
              </span>
            </span>
            <span className="flex flex-row justify-start w-full items-center text-left">
              <WhiteTickSVg />
              <span className="text-xs font-[600] ml-2">
                Progress tracking for your child
              </span>
            </span>
            <span className="flex flex-row justify-start w-full items-center text-left">
              <WhiteTickSVg />
              <span className="text-xs font-[600] ml-2">
                Get speech analysis for your child
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PremiumCards;
