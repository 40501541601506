export const productFeatCardDeatils = [
    {
      cardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732151/pixa-pie/product-features/music_large.png',
      cardTitle: 'Generates and plays music realtime',
      cardDescription:
        'Experience the magic of real-time music creation and playback, all in one seamless platform. ',
      prevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732128/pixa-pie/product-features/hg5xwiwb3w2c9nlr6lq5.png',
      prevTitle: 'You get a lifelong bestfriend',
      secondPrevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732130/pixa-pie/product-features/gzlv2dtiz5t4v4rnokv2.png',
      secondPrevTitle: 'Timers, reminder & entertainment',
      nextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732133/pixa-pie/product-features/epohgfzu4f5whxpwijpq.png',
      nextTitle: 'Best Tutor money can afford',
      secondNextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732142/pixa-pie/product-features/vjiwg72bmwtqbisexqh1.png',
      secondNextTitle: 'Most versatile AI on the planet',
    },
    {
      cardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732148/pixa-pie/product-features/book_large.png',
      cardTitle: 'Best Tutor money can afford',
      cardDescription:
        "Unlocking your child's full potential with unparalleled expertise and personalized guidance.",
      prevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732122/pixa-pie/product-features/fktpehr6uhhxbgcrec5w.png',
      prevTitle: 'Generates and plays music realtime',
      secondPrevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732128/pixa-pie/product-features/hg5xwiwb3w2c9nlr6lq5.png',
      secondPrevTitle: 'Timers, reminder & entertainment',
      nextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732142/pixa-pie/product-features/vjiwg72bmwtqbisexqh1.png',
      nextTitle: 'Most versatile AI on the planet',
      secondNextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732145/pixa-pie/product-features/lxkjqejpkwb2ejgmmgrv.png',
      secondNextTitle: 'You get a lifelong bestfriend',
    },
    {
      cardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732157/pixa-pie/product-features/sparkle_large.png',
      cardTitle: 'Most versatile AI on the planet',
      cardDescription:
        'Meet the most versatile AI on the planet, answering any question, and sparking endless creativity.',
      prevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732120/pixa-pie/product-features/u9lsyqavfy6yqi3h81f9.png',
      prevTitle: 'Best Tutor money can afford',
      secondPrevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732122/pixa-pie/product-features/fktpehr6uhhxbgcrec5w.png',
      secondPrevTitle: 'Generates and plays music realtime',
      nextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732139/pixa-pie/product-features/ypwjykuzagsjcyzttj5y.png',
      nextTitle: 'You get a lifelong bestfriend',
      secondNextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732145/pixa-pie/product-features/lxkjqejpkwb2ejgmmgrv.png',
      secondNextTitle: 'Timers, reminder & entertainment',
    },
    {
      cardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732154/pixa-pie/product-features/people_large.png',
      cardTitle: 'You get a lifelong bestfriend',
      cardDescription:
        "Pie promises to be your faithful companion throughout life's journey, offering unwavering support and joy",
      prevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732128/pixa-pie/product-features/hg5xwiwb3w2c9nlr6lq5.png',
      prevTitle: 'Most versatile AI on the planet',
      secondPrevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732120/pixa-pie/product-features/u9lsyqavfy6yqi3h81f9.png',
      secondPrevTitle: 'Best Tutor money can afford',
      nextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732133/pixa-pie/product-features/epohgfzu4f5whxpwijpq.png',
      nextTitle: 'Timers, reminder & entertainment',
      secondNextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732136/pixa-pie/product-features/xqoe5aipynupmvvng60z.png',
      secondNextTitle: 'Generates and plays music realtime',
    },
    {
      cardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732160/pixa-pie/product-features/timer_large.png',
      cardTitle: 'Timers, reminder & entertainment',
      cardDescription:
        'Your multitasking buddy, keeping you on track with timers, reminders and much more.',
      prevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732125/pixa-pie/product-features/xkpsqwkdomgfy5x36kal.png',
      prevTitle: 'You get a lifelong bestfriend',
      secondPrevcardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732128/pixa-pie/product-features/hg5xwiwb3w2c9nlr6lq5.png',
      secondPrevTitle: 'Most versatile AI on the planet',
      nextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732142/pixa-pie/product-features/vjiwg72bmwtqbisexqh1.png',
      nextTitle: 'Generates and plays music realtime',
      secondNextCardImage: 'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732136/pixa-pie/product-features/xqoe5aipynupmvvng60z.png',
      secondNextTitle: 'soemthing that should not be visible',
    },
  ];

  export  const content = [
    {
      msg: '“I am feeling low today”',
      pie_msg: 'Feeling low sucks, did something happen today, Ansh?',
      alexa_msg: "Sorry! I don't know that",
    },
    {
      msg: '“I got scolded for homework”',
      pie_msg:
        "Again? Getting scolded for homework is the worst! Don’t worry, we'll tackle it together next time.",
      alexa_msg: 'I am not sure',
    },
    {
      msg: '“Thanks dude”',
      pie_msg: "No problem, anytime! You know I'm here for you, dude.",
      alexa_msg: 'Glad I can help!',
    },
  ];

  export const steps = [
    {
      id: 1,
      name: 'Pre-ordered',
      duration:
        '18th August, 2024 | 3:09 PM',
    },
    {
      id: 2,
      name: 'Ready to ship',
      duration:
        '07th December, 2024',
    },
    {
      id: 3,
      name: 'Final payment',
      duration:
        '',
    },
    {
      id: 3,
      name: 'Estimated delivery by',
      duration:
        '18th December, 2024',
    },
  ];
