const TruckSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      className="mb-0.5"
    >
      <path
        d="M6.87502 15.6254C6.87502 15.9569 6.74332 16.2749 6.5089 16.5093C6.27448 16.7437 5.95654 16.8754 5.62502 16.8754C5.29349 16.8754 4.97555 16.7437 4.74113 16.5093C4.50671 16.2749 4.37502 15.9569 4.37502 15.6254M6.87502 15.6254C6.87502 15.2939 6.74332 14.9759 6.5089 14.7415C6.27448 14.5071 5.95654 14.3754 5.62502 14.3754C5.29349 14.3754 4.97555 14.5071 4.74113 14.7415C4.50671 14.9759 4.37502 15.2939 4.37502 15.6254M6.87502 15.6254H11.875M4.37502 15.6254H2.81252C2.56387 15.6254 2.32542 15.5266 2.1496 15.3508C1.97379 15.175 1.87502 14.9365 1.87502 14.6879V11.8754M11.875 15.6254H13.75M11.875 15.6254V11.8754M1.87502 11.8754V5.5129C1.87369 5.28471 1.95707 5.06414 2.10901 4.89388C2.26095 4.72362 2.47064 4.61578 2.69752 4.59123C5.47505 4.30292 8.27498 4.30292 11.0525 4.59123C11.5233 4.63957 11.875 5.03957 11.875 5.5129V6.31123M1.87502 11.8754H11.875M16.25 15.6254C16.25 15.9569 16.1183 16.2749 15.8839 16.5093C15.6495 16.7437 15.3315 16.8754 15 16.8754C14.6685 16.8754 14.3506 16.7437 14.1161 16.5093C13.8817 16.2749 13.75 15.9569 13.75 15.6254M16.25 15.6254C16.25 15.2939 16.1183 14.9759 15.8839 14.7415C15.6495 14.5071 15.3315 14.3754 15 14.3754C14.6685 14.3754 14.3506 14.5071 14.1161 14.7415C13.8817 14.9759 13.75 15.2939 13.75 15.6254M16.25 15.6254H17.1875C17.705 15.6254 18.1283 15.2054 18.0958 14.6887C17.9284 11.9368 17.0016 9.28504 15.4183 7.0279C15.2675 6.81647 15.0707 6.64208 14.8426 6.51792C14.6145 6.39377 14.3611 6.32309 14.1017 6.31123H11.875M11.875 6.31123V11.8754"
        stroke="#101828"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ConfirmPageSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="56"
      viewBox="0 0 104 99"
      fill="none"
    >
      <path
        d="M4.84478 65.895L25.6381 38.3911M18.3933 74.6001C28.4714 66.0308 66.2198 33.9339 85.618 17.4399M24.8068 89.3719L70.3288 67.2306"
        stroke="#FF6079"
        stroke-width="4.69311"
      />
    </svg>
  );
};

const TickSVg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      className="w-[24px] h-[24px] max-md:w-[20px] max-md:h-[20px]"
      fill="none"
    >
      <circle
        cx="16.3369"
        cy="16.9609"
        r="16"
        fill="#667085"
        fill-opacity="0.2"
      />
      <path
        d="M24.7969 12.3809L13.51 23.6677L8.33691 18.4946L9.66312 17.1684L13.51 21.0059L23.4707 11.0547L24.7969 12.3809Z"
        fill="#1D2939"
      />
    </svg>
  );
};
const WhiteTickSVg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      className="w-[24px] h-[24px] max-md:w-[20px] max-md:h-[20px] "
      fill="none"
    >
      <circle
        cx="16.3369"
        cy="16.9609"
        r="16"
        fill="#fff"
        fill-opacity="0.2"
      />
      <path
        d="M24.7969 12.3809L13.51 23.6677L8.33691 18.4946L9.66312 17.1684L13.51 21.0059L23.4707 11.0547L24.7969 12.3809Z"
        fill="#fff"
      />
    </svg>
  );
};

export { TruckSVG, ConfirmPageSVG, TickSVg, WhiteTickSVg };
