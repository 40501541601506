import { motion } from 'framer-motion';

const ProductfeatHeading = ({ isVisible }: any) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <div className="w-full justify-center items-center text-center flex overflow-hidden">
      {isVisible && (
        <motion.div
          className="flex flex-col w-full justify-center items-center text-center"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <div
            className="w-[30%] xxl:w-[25%] max-lg:w-[60%] absolute max-lg:relative -left-10 max-xl:-left-4 xxl:-left-2  max-xl:top-8 top-0 z-[150] font-extrabold text-center text-white pl-4 xxl:pl-2 max-lg:mt-12 mt-[6rem]"
            style={{
              fontSize: 'clamp(1.5rem, 2.0vw + 1rem, 3.725rem)',
              lineHeight: 'clamp(2rem, 2.5vw + 1rem, 4.5rem)',
            }}
          >
            One pixa for all your needs
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default ProductfeatHeading;
