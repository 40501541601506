import { motion } from 'framer-motion';
import ProductDemoHeading from '../components/productDemoHeading';
import { useInView } from 'react-intersection-observer';
import React from 'react';
import Slider from 'react-slick';

function Prodapp() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };
  return (
    <div className="w-[90%] flex flex-col pt-20 justify-start items-center space-y-16 max-md:space-y-8 z-[100] bg-product">
      <ProductDemoHeading />
      <div
        ref={ref}
        className="grid grid-cols-3 grid-rows-1 max-md:grid-cols-1 gap-[1rem] max-md:gap-0 max-md:grid-rows-3 w-full max-md:space-y-8 overflow-hidden min-h-[80vh] max-xl:min-h-0 max-md:hidden"
      >
        {inView && (
          <React.Fragment>
            <motion.div
              initial={{ y: '100vh' }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, stiffness: 120, type: 'slide' }}
              className="flex flex-col justify-start items-center bg-[#f4f4f4] rounded-3xl"
            >
              <span className="flex flex-col justify-center items-center my-8">
                <span className="font-bold w-full text-center justify-center items-center text-[#424242] text-4xl xxl:text-[3rem] max-md:text-[1.5rem]">
                  Statistics
                </span>
                <span className="font-normal w-10/12 max-md:w-11/12 text-center justify-center items-center text-[#717171] xxl:text-[1.5rem] text-[1rem] max-md:text-[0.875rem] lg:px-8 mt-2">
                  Track progress of your child and get weekly reports
                </span>
              </span>
              <img
                src={
                  'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732830/pixa-pie/pixa-app/app_demo_1.webp'
                }
                alt="pixa app demo"
                className="w-full h-[calc(100vh-8rem)] max-xl:h-min object-contain object-top"
              />
            </motion.div>
            <motion.div
              initial={{ y: '-100vh' }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, stiffness: 120, type: 'slide' }}
              className="flex flex-col-reverse justify-start items-center bg-[#f4f4f4] rounded-3xl"
            >
              <span className="flex flex-col justify-center items-center text-center my-8">
                <span className="font-bold w-full text-center justify-center items-center text-[#424242] text-4xl max-xl:text-4xl xxl:text-[3rem] max-md:text-[1.5rem]">
                  Parental control
                </span>
                <span className="font-normal w-9/12 max-md:w-11/12 text-center justify-center items-center text-[#717171] xxl:text-[1.5rem] text-[1rem] max-md:text-[0.875rem] lg:px-8 mt-2">
                  Control how your kid is interacting with pixa
                </span>
              </span>
              <img
                src={
                  'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732825/pixa-pie/pixa-app/app_demo_2.webp'
                }
                alt="pixa app demo"
                className="w-full h-[calc(100vh-8rem)] max-xl:h-min object-contain object-top"
              />
            </motion.div>
            <motion.div
              initial={{ y: '100vh' }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, stiffness: 120, type: 'slide' }}
              className="flex flex-col justify-start items-center bg-[#f4f4f4] rounded-3xl"
            >
              <span className="flex flex-col justify-center items-center text-center my-8">
                <span className="font-bold w-full text-center justify-center items-center text-[#424242] text-4xl xxl:text-[3rem] max-md:text-[1.5rem]">
                  Insights
                </span>
                <span className="font-normal max-xl:w-full w-9/12 max-md:w-11/12 text-center justify-center items-center text-[#717171] xxl:text-[1.5rem] text-[1rem] max-md:text-[0.875rem] lg:px-8 mt-2">
                  Generate insights about what your kid is doing with pixa
                </span>
              </span>
              <img
                src={
                  'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732827/pixa-pie/pixa-app/app_demo_3.webp'
                }
                alt="pixa app demo"
                className="w-full h-[calc(100vh-8rem)] max-xl:h-min object-contain object-top"
              />
            </motion.div>
          </React.Fragment>
        )}
      </div>

      <Slider {...settings} className="md:hidden w-full h-full pb-4">
        <div className="flex flex-col justify-start items-center bg-[#f4f4f4] rounded-3xl px-2 pt-4">
          <span className="flex flex-col justify-center items-center mb-4 mt-2">
            <span className="font-bold w-full text-center justify-center items-center text-[#424242] text-4xl xxl:text-[3rem] max-md:text-[1.5rem]">
              Statistics
            </span>
            <span className="font-normal w-9/12 max-md:w-11/12 text-center justify-center items-center text-[#717171] xxl:text-[1.5rem] text-[1rem] max-md:text-[0.875rem] lg:px-8 mt-2">
              Track progress of your child and get weekly reports
            </span>
          </span>
          <img
            src={
              'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732830/pixa-pie/pixa-app/app_demo_1.webp'
            }
            alt="pixa app demo"
            className="xl:w-[90%] object-contain object-center"
          ></img>
        </div>
        <div className="flex flex-col justify-start items-center bg-[#f4f4f4] rounded-3xl px-2">
          <img
            src={
              'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732825/pixa-pie/pixa-app/app_demo_2.webp'
            }
            alt="pixa app demo"
            className="xl:w-[90%] object-contain object-center"
          ></img>
          <span className="flex flex-col justify-center items-center mb-4 mt-2 pb-4">
            <span className="font-bold w-full text-center justify-center items-center text-[#424242] text-4xl xxl:text-[3rem] max-md:text-[1.5rem]">
              Parental control
            </span>
            <span className="font-normal w-9/12 max-md:w-10/12 text-center justify-center items-center text-[#717171] xxl:text-[1.5rem] text-[1rem] max-md:text-[0.875rem] lg:px-8 mt-2">
              Control how your kid is interacting with pixa
            </span>
          </span>
        </div>
        <div className="flex flex-col justify-start items-center bg-[#f4f4f4] rounded-3xl px-2 pt-4">
          <span className="flex flex-col justify-center items-center mb-4 mt-2">
            <span className="font-bold w-full text-center justify-center items-center text-[#424242] text-4xl xxl:text-[3rem] max-md:text-[1.5rem]">
              Insights
            </span>
            <span className="font-normal w-9/12 max-md:w-11/12 text-center justify-center items-center text-[#717171] xxl:text-[1.5rem] text-[1rem] max-md:text-[0.875rem] lg:px-8 mt-2">
              Generate insights about what your kid is doing with pixa
            </span>
          </span>
          <img
            src={
              'https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723732827/pixa-pie/pixa-app/app_demo_3.webp'
            }
            alt="pixa app demo"
            className="xl:w-[90%] object-contain object-center"
          ></img>
        </div>
      </Slider>
    </div>
  );
}

export default Prodapp;
