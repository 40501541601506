import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();
  return (
    <div className="w-full absolute top-0 left-0 bg-transparent-500 h-16 text-center flex justify-start pt-2 items-center">
      <span
        className="lg:pl-16 md:pl-8 max-md:pl-8 lg:pt-8 md:pt-6 max-md:pt-4 cursor-pointer"
        onClick={() => {
          navigate('/');
        }}
      >
        <img
          src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723727518/pixa-pie/pixa_dark_logo.svg"
          alt="Logo"
          className="h-6 md:h-8"
          style={{ objectFit: 'contain' }}
        />
      </span>
    </div>
  );
}

export default Navbar;
