import React, { useEffect, useState, useRef, memo } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import ErrorBoundary from './components/errorBoundary';
import ProductNavbar from './components/productNavbar';
import Producthero from './screens/producthero';
import Productfeat from './screens/productfeat';
import Prodapp from './screens/prodapp';
import Productsubs from './screens/productsubs';
import Footer from './components/footer';
import { useNavigate } from 'react-router-dom';
import PremiumCards from './screens/premiumcards';

const Product = () => {
  const [showSalesPitch, setShowSalesPitch] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const productFeatRef = useRef(null);
  const footerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setShowSalesPitch(true);
        } else {
          setShowSalesPitch(false);
        }
      },
      {
        threshold: 0.1,
      }
    );

    const footerObserver = new IntersectionObserver(
      ([entry]) => {
        setIsFooterVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (productFeatRef.current) {
      observer.observe(productFeatRef.current);
    }

    if (footerRef.current) {
      footerObserver.observe(footerRef.current);
    }

    return () => {
      if (productFeatRef.current) {
        observer.unobserve(productFeatRef.current);
      }

      if (footerRef.current) {
        footerObserver.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Pixa</title>
        <meta name="title" content="Pixa" />
        <meta
          name="description"
          content="Pixa - Building real life toy story"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.heypixa.ai/product" />
        <meta property="og:title" content="Pixa" />
        <meta
          property="og:description"
          content="Pixa - Building real life toy story"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1725193956/pixa-pie/product-preview.webp"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.heypixa.ai/product" />
        <meta property="twitter:title" content="Pixa" />
        <meta
          property="twitter:description"
          content="Pixa - Building real life toy story"
        />
        <meta
          property="twitter:image"
          content="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1725193956/pixa-pie/product-preview.webp"
        />
      </Helmet>
      <div className="bg-product font-sora flex flex-col items-center overflow-hidden relative">
        <div ref={productFeatRef}>
          <ErrorBoundary>
            <ProductNavbar />
          </ErrorBoundary>
          <ErrorBoundary>
            <Producthero />
          </ErrorBoundary>
          <ErrorBoundary>
            <Productfeat />
          </ErrorBoundary>
        </div>
        <ErrorBoundary>
          <Prodapp />
        </ErrorBoundary>

        {/* <motion.div
          initial={{ y: '100%' }}
          animate={showSalesPitch ? { y: 0 } : { y: '100%' }}
          exit={{ y: '100%' }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          className={`${
            isFooterVisible ? 'hidden' : 'fixed'
          } bg-[#fff] sale-pitch h-min w-full rounded-t-[2rem] flex justify-between items-center text-center z-[9999] lg:px-16 md:px-8 max-md:px-4 flex-row bottom-0 max-md:py-4`}
        >
          <span className="flex flex-row lg:space-x-8 md:space-x-2">
            <img
              src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723784096/pixa-pie/product/fixed_sale_bar_img.png"
              alt="feat"
              className="bg-no-repeat object-bottom object-contain h-24 w-24 max-md:h-16 max-md:w-16 rotate-[360deg]"
            />
            <p className="lg:text-[2rem] xxl:text-[2.5rem] md:text-[1.725rem] max-sm:text-[1rem] font-bold flex justify-center items-center text-left max-md:pl-2">
              Pixa + 3 - months premium plan
            </p>
          </span>
          <button
            className="text-white bg-pink p-4 px-8 max-md:p-2 max-md:px-4 text-[1.5rem] xxl:text-[1.725rem] xxl:leading-[2.5rem] leading-[1.5rem] max-md:text-[1rem] rounded-2xl font-semibold shadow-inner shadow-white flex flex-row justify-center items-center max-md:ml-4"
            onClick={() => {
              navigate('/checkout');
            }}
            type="submit"
          >
            <span>Pre</span>
            <span>-</span>
            <span>order</span>
          </button>
        </motion.div> */}

        <ErrorBoundary>
          <Productsubs />
        </ErrorBoundary>
        <ErrorBoundary>
          <PremiumCards />
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <div ref={footerRef} className="min-w-full">
          <Footer />
        </div>
      </ErrorBoundary>
    </React.Fragment>
  );
};

export default memo(Product);
