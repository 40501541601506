'use client';
import { useEffect, useState } from 'react';
import { useForm } from '@formspree/react';
import { Footer, Navbar } from '../components';

const Support = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const key = 'xqkopdvb';
  const [loading, setLoading] = useState(false);
  const [state, handleSubmit] = useForm(key);
  const notifySuccess = () => alert('Form submitted successfully!');

  useEffect(() => {
    if (state.succeeded) {
      notifySuccess();
      setLoading(false);
      setForm({
        name: '',
        email: '',
        message: '',
      });
    }
    if (state.submitting) {
      setLoading(true);
    }
  }, [state.submitting, state.succeeded]);

  const handleChange = (e: any) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };
  window.onload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-product font-sora justify-start items-center text-[16px] max-md:text-[14px] lg:px-16 md:px-8 max-md:px-4 max-md:pt-8 min-w-full text-black">
        <span className="text-7xl max-md:text-4xl max-sm:text-4xl min-w-full flex py-24 mt-20 max-md:mt-16  max-md:py-20 max-sm:py-12 font-bold justify-center items-center text-center max-sm:leading-10 text-black flex-col">
          <div className="relative flex justify-center items-center w-full">
            <div className="px-20 max-md:px-12 font-bold text-black text-center font-inherit relative">
              <img
                src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724583362/pixa-pie/wzp2y17kffzrnk1vrvqt.png"
                alt="arrow-left"
                className="absolute left-0 top-0 w-[64.748px] max-md:w-[40px] h-[24.521px] max-md:h-[20px] rotate-[-155.302deg]"
              />
              <p>We're here to help</p>
              <span className="absolute -bottom-5 max-md:-bottom-3 h-[12px] max-md:h-[8px] bg-product-subs-heading-underline-pattern  bg-no-repeat w-[80%] max-md:w-[70%] left-16 max-md:left-12 bg-100"></span>
              <img
                src="https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724583362/pixa-pie/wzp2y17kffzrnk1vrvqt.png"
                alt="arrow-right"
                className="absolute right-0 top-0 w-[64.748px] max-md:w-[40px] h-[24.521px] max-md:h-[20px] rotate-[-8.571deg]"
              />
            </div>
          </div>

          <span className="text-policy text-[24px] max-md:text-[16px] font-[400] py-8 max-md:py-4 xl:w-[55%] lg:w-[65%] md:w-[80%] max-sm:w-full leading-[36px] max-md:leading-6">
            Thank you for visiting our support page. Here, you can find
            information about how we handle your data and a form to request the
            deletion of your data from our systems.
          </span>
        </span>
        <div className="flex flex-row max-md:flex-col-reverse justify-center items-center text-center space-x-8 max-md:space-x-0">
          <div className="w-full flex justify-center items-start py-8 px-4 text-center">
            <div className="flex flex-col justify-center items-center text-left w-full py-8 max-md:py-2">
              <span className="xl:text-[48px] md:text-[42px] max-md:text-[36px] max-md:text-center font-bold text-black w-full">
                Data usage terms
              </span>
              <span>
                <br />
                <br />
                <b className="text-[20px]">Data Collection</b>
                <br />
                We collect data to enhance your experience with our AI toys and
                provide you with better services. The types of data we collect
                include: <br />
                Personal Information: Name, email address, and contact details.{' '}
                <br />
                Usage Data: Interaction patterns, preferences, and usage
                frequency. <br />
                Device Information: Device type, operating system, and unique
                device identifiers.
                <br />
                <br />
                <b className="text-[20px]">Data Storage</b>
                <br />
                Your data is securely stored on our encrypted servers. We follow
                industry-standard practices to ensure the security and
                confidentiality of your data.
                <br />
                <br />
                <b className="text-[20px]">Data Protection</b> <br />
                We do not share your personal data with third parties except in
                the following circumstances: <br />
                <ul className="list-disc pl-5">
                  <li>With your consent</li>
                  <li>
                    For legal reasons, such as complying with laws and
                    regulations
                  </li>
                  <li>
                    With service providers who assist us in our operations,
                    under strict confidentiality agreements
                  </li>
                </ul>
                <br />
                <b className="text-[20px]">User Rights</b>
                <br />
                You have the right to:
                <br />
                <ul className="list-disc pl-5">
                  <li>Access the data we hold about you</li>
                  <li>Correct any inaccuracies in your data</li>
                  <li>Request the deletion of your data</li>
                  <li>Withdraw consent for data processing</li>
                </ul>
              </span>
            </div>
          </div>
          <div className="w-full flex justify-center items-start py-8 max-md:py-2 px-4 max-md:px-0 text-center">
            <div className="bg-[#F4F4F4] rounded-[24px] flex flex-col justify-center items-center text-center w-full py-8 max-md:py-2">
              <span className="text-[42px] max-md:text-[36px] font-bold text-black">
                Delete your data
              </span>
              <label className="text-policy text-[16px] text-left w-[80%] flex pt-16 max-md:pt-8 pb-2">
                Name
              </label>
              <input
                type="text"
                title="Name"
                name="name"
                value={form.name}
                onChange={handleChange}
                className="w-[80%] h-[48px] rounded-[8px] border-2 border-black border-solid px-4"
              />
              <label className="text-policy text-[16px] text-left w-[80%] flex pt-4 pb-2">
                E-mail
              </label>
              <input
                type="email"
                title="E-mail"
                name="email"
                value={form.email}
                onChange={handleChange}
                className="w-[80%] h-[48px] rounded-[8px] border-2 border-black border-solid px-4"
              />
              <label className="text-policy text-[16px] text-left w-[80%] flex pt-4 pb-2">
                Why you want to delete?
              </label>
              <textarea
                title="details"
                name="message"
                value={form.message}
                onChange={handleChange}
                className="w-[80%] h-[120px] rounded-[8px] border-2 border-black border-solid p-2 px-4"
              ></textarea>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit(form);
                }}
                disabled={state.submitting}
                className="text-white bg-pink text-[16px] text-center w-[80%] flex justify-center items-center rounded-[16px] my-8 xl:my-16 lg:my-12 p-4"
              >
                {loading ? 'Sending...✈️' : 'Submit'}
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default Support;
