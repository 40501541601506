import { useEffect, useState } from 'react';
import { styles } from '../styles';

type Props = {
  feature: {
    Heading: string;
    Reading: {
      Pie: string;
      Alexa: string;
      Google: string;
      Apple: string;
    };
    Value: string;
  };
};

function Card({ feature }: Props) {
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    setStartAnimation(true);
    const timeoutId = setTimeout(() => setStartAnimation(false), 2000);
    return () => clearTimeout(timeoutId);
  }, [feature]);

  return (
    <div className="flex flex-col justify-start items-center w-[90%] max-sm:w-[90%] max-md:text-0 space-y-11 max-xl:space-y-12 max-lg:space-y-11 max-md:space-y-8 px-8 max-md:px-0 py-12">
      <div className="text-3xl max-lg:text-4xl max-md:text-xl font-semibold w-full text-white text-left px-2 max-md:px-0">
        {feature.Heading}
      </div>
      <div className="w-full flex flex-col justify-start items-start">
        <span className="w-full flex flex-row space-x-2 justify-between items-center pl-2 max-md:pl-0">
          <span
            className={`h-3 max-lg:h-2 rounded-xl pixa-strip-main ${
              startAnimation ? 'animate-growWidth' : ''
            }`}
            style={
              {
                width: feature.Reading.Pie,
                '--feature-width': feature.Reading.Pie,
              } as React.CSSProperties
            }
          ></span>

          <span
            className={`${styles.goldenText} font-bold text-5xl max-lg:text-4xl max-md:text-2xl`}
          >
            {feature.Value}
          </span>
        </span>
        <span className="text-white/90 text-2xl max-lg:text-xl max-md:text-lg font-semibold text-left w-full -mt-2">
          <span className="px-2 max-md:px-0">Pixa - AI friend</span>
        </span>
      </div>
      <div className="w-full flex flex-col justify-start items-start">
        <span className="w-full flex flex-row space-x-1 justify-between items-center pl-2 max-md:pl-0 pb-4 max-sm:pb-2">
          <span
            className={`h-3 max-lg:h-2 rounded-xl others-strip-main ${
              startAnimation ? 'animate-growWidth' : ''
            }`}
            style={
              {
                width: feature.Reading.Alexa,
                '--feature-width': feature.Reading.Alexa,
              } as React.CSSProperties
            }
          ></span>
        </span>
        <span className="text-white/90 text-2xl max-lg:text-xl max-md:text-lg font-semibold text-left w-full -mt-2">
          <span className="px-2 max-md:px-0">Miko Robots</span>
        </span>
      </div>
      <div className="w-full flex flex-col justify-start items-start">
        <span className="w-full flex flex-row space-x-1 justify-between items-center pl-2 max-md:pl-0 pb-4 max-sm:pb-2">
          <span
            className={`h-3 max-lg:h-2 rounded-xl others-strip-main ${
              startAnimation ? 'animate-growWidth' : ''
            }`}
            style={
              {
                width: feature.Reading.Google,
                '--feature-width': feature.Reading.Google,
              } as React.CSSProperties
            }
          ></span>
        </span>
        <span className="text-white/90 text-2xl max-lg:text-xl max-md:text-lg font-semibold text-left w-full -mt-2">
          <span className="px-2 max-md:px-0">Amazon Alexa</span>
        </span>
      </div>
      <div className="w-full flex flex-col justify-start items-start">
        <span className="w-full flex flex-row space-x-1 justify-between items-center pl-2 max-md:pl-0 pb-4 max-sm:pb-2 -mt-2">
          <span
            className={`h-3 max-lg:h-2 rounded-xl others-strip-main ${
              startAnimation ? 'animate-growWidth' : ''
            }`}
            style={
              {
                width: feature.Reading.Apple,
                '--feature-width': feature.Reading.Apple,
              } as React.CSSProperties
            }
          ></span>
        </span>
        <span className="text-white/90 text-2xl max-lg:text-xl max-md:text-lg font-semibold text-left w-full -mt-2">
          <span className="px-2 max-md:px-0">Apple Homepods</span>
        </span>
      </div>
    </div>
  );
}

export default Card;
