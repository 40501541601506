import { useState, useRef, useCallback } from 'react';
import { productFeatCardDeatils } from '../cont';
import Productfeatcard from './productfeatcard';

const useThrottle = (
  callback: (event: React.WheelEvent<HTMLDivElement>) => void,
  delay: number
) => {
  const lastCall = useRef(0);

  return useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      const now = new Date().getTime();
      if (now - lastCall.current >= delay) {
        lastCall.current = now;
        callback(event);
      }
    },
    [callback, delay]
  );
};

function ProductfeatAnimation() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const productFeatCardDeatilsLength = productFeatCardDeatils.length;
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    const { deltaY } = e;
    if (deltaY < 0) {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    } else {
      if (currentIndex < productFeatCardDeatilsLength - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    }
  };

  const throttledHandleWheel = useThrottle(handleWheel, 400);

  return (
    <div
      ref={scrollContainerRef}
      className="h-screen bg-transparent relative  overflow-y-auto min-w-full flex flex-col product-scrollbar"
      onWheel={throttledHandleWheel}
    >
      {productFeatCardDeatils.map((details, index) => (
        <div
          key={index}
          className={`absolute bottom-0 z-1 ml-[40%] ${
            index === currentIndex ? '' : 'hidden'
          }`}
        >
          <Productfeatcard cardDetails={details} index={index} />
        </div>
      ))}

      {productFeatCardDeatils.map((_, index) => (
        <div key={index} className="h-[500px]"></div>
      ))}
    </div>
  );
}

export default ProductfeatAnimation;
