import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ProductSubsHeading = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const textVariants = {
    hidden: { y: 300, rotate: 15 },
    show: (custom: number) => ({
      y: 0,
      rotate: 0,
      transition: {
        y: {
          type: 'slide',
          stiffness: 260,
          damping: 20,
          delay: custom * 0.2,
        },
        rotate: {
          duration: 0.5,
          delay: custom * 0.2,
        },
      },
    }),
  };

  return (
    <div
      className="w-full justify-center items-center text-center flex overflow-hidden max-md:hidden"
      ref={ref}
    >
      {inView && (
        <motion.div
          className="flex flex-col justify-center items-center text-center font-extrabold text-[#344054] text-[4rem] xxl:text-[4.5rem] max-md:text-[2rem] max-xs:text-[1.725rem] leading-none mb-4 space-y-2"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <motion.span
            variants={textVariants}
            custom={0.5}
            style={{ originX: 0 }}
          >
            <span className="mb-4">Only subscription your</span>
          </motion.span>
          <motion.span
            className="flex flex-row"
            variants={textVariants}
            custom={1.5}
            style={{ originX: 0 }}
          >
            <span>kid will</span>
            <span className="flex flex-col relative mx-3">
              <span>ever need</span>
              <motion.span
                className="absolute -bottom-3 max-md:-bottom-2 h-[12px] max-md:h-[8px] bg-product-subs-heading-underline-pattern bg-100 w-[100%]"
                initial={{ y: '100vh' }}
                animate={{ y: 0 }}
                transition={{ delay: 1, type: 'slide' }}
              ></motion.span>
            </span>
          </motion.span>
        </motion.div>
      )}
    </div>
  );
};

export default ProductSubsHeading;
